import { NotificationHub } from "@nef/core";
import { useFormContext, useFormDispatch } from "components/form";
import { PopoverDropdown2 } from "components/popoverDropdown";
import React, { useCallback } from "react";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { useFormLayoutContext } from "./formLayoutContext";
import { FieldNames, SelectOptions } from "components/fields";

export const FormLayoutSaveDropdown = ({ form, showMenu, toolDispatch, target }) => {
  const [formLayoutData, formLayoutDispatch] = useFormLayoutContext();
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [user] = useUserContext();

  const setShowMenu = useCallback(
    val => {
      toolDispatch({
        type: "SET_SHOW_MENU",
        payload: val,
      });
    },
    [toolDispatch]
  );

  const onRemoveSuccess = useCallback(
    key => {
      NotificationHub.send("success", "Defaults have been removed");
      formLayoutDispatch({
        type: "SET_LAYOUT_SAVING",
        payload: { form, layout: key, isSaving: false },
      });
    },
    [formLayoutDispatch, form]
  );

  const onRemoveError = useCallback(
    key => {
      NotificationHub.send("danger", "Failed to remove defaults");
      formLayoutDispatch({
        type: "SET_LAYOUT_SAVING",
        payload: { form, layout: key, isSaving: false },
      });
    },
    [formLayoutDispatch, form]
  );

  const removeLayout = useCallback(
    key => {
      const { layouts } = formLayoutData[form.key];
      delete layouts[key];
      const actions = [
        {
          type: "REMOVE_FORM_LAYOUT",
          payload: { form, key },
        },
        {
          type: "SET_LAYOUT_SAVING",
          payload: { form, layout: key, isSaving: true },
        },
      ];
      formLayoutDispatch(actions);
      network().writeSettings(
        {
          data: JSON.stringify({
            ...layouts,
          }),
          name: form.key,
          type: "form_fields",
        },
        onRemoveSuccess,
        onRemoveError,
        key
      );
    },
    [formLayoutData, form, onRemoveError, formLayoutDispatch, onRemoveSuccess]
  );

  const onSaveSuccess = useCallback(
    key => {
      NotificationHub.send("success", "Defaults have been saved");
      toolDispatch({
        type: "FINISH_SAVE",
      });
      formLayoutDispatch({
        type: "SET_LAYOUT_SAVING",
        payload: { form, layout: key, isSaving: false },
      });
    },
    [toolDispatch, formLayoutDispatch, form]
  );

  const onSaveError = useCallback(
    key => {
      NotificationHub.send("danger", "Failed to save defaults", {
        subtitle: "Please try again",
      });
      formLayoutDispatch({
        type: "SET_LAYOUT_SAVING",
        payload: { form, layout: key, isSaving: false },
      });
    },
    [formLayoutDispatch, form]
  );

  const handleSetActiveLayout = useCallback(
    key => {
      formLayoutDispatch({
        type: "SET_ACTIVE_FORM_LAYOUT",
        payload: { form, key },
      });

      const newLayout = { ...formLayoutData[form.key].layouts[key] };
      if (newLayout[FieldNames.mpid]) {
        let savedMpids = newLayout[FieldNames.mpid] || [];
        if (!Array.isArray(savedMpids)) {
          savedMpids = [savedMpids];
        }

        const options = (SelectOptions[FieldNames.mpid](form) || []).reduce((valueSet, option) => {
          valueSet.add(option.value);
          return valueSet;
        }, new Set());

        const mismatch = savedMpids.reduce((acc, curr) => {
          if (!options.has(curr.value)) {
            acc.push(curr.value);
          }
          return acc;
        }, []);

        if (mismatch.length) {
          delete newLayout[FieldNames.mpid];

          NotificationHub.send(
            "warning",
            `Saved form layout ${key} contains MPIDs that are no longer associated with this user. MPIDs will not load for this form layout`,
            { subtitle: `The following MPIDs are no longer valid: ${mismatch.join(", ")}` }
          );
        }
      }

      formDispatch([
        {
          type: "SET_FORM_VALUES",
          payload: {
            form,
            fields: newLayout,
          },
        },
        {
          type: "INIT_FORM_VALIDATION",
          payload: { form, entitlements: user.entitlements },
        },
      ]);
    },
    [form, formLayoutData, formLayoutDispatch, formDispatch, user.entitlements]
  );

  const handleSaveLayout = useCallback(
    key => {
      const isNew = Object.keys(formLayoutData[form.key].layouts).indexOf(key) === -1;
      if (
        isNew &&
        Object.keys(formLayoutData[form.key].layouts).length >=
          formLayoutData[form.key].maxNumLayout
      ) {
        NotificationHub.send(
          "danger",
          `Unable to save more than ${formLayoutData[form.key].maxNumLayout} defaults`
        );
      } else {
        const fields = formData[form.key].fields;
        const actions = [
          {
            type: "SET_LAYOUT_SAVING",
            payload: { form, layout: key, isSaving: true },
          },
          {
            type: "SAVE_LAYOUT",
            payload: { form, key, fields, isActive: true },
          },
        ];
        formLayoutDispatch(actions);
        network().writeSettings(
          {
            data: JSON.stringify({
              ...formLayoutData[form.key].layouts,
              [key]: formData[form.key].fields,
            }),
            name: form.key,
            type: "form_fields",
          },
          onSaveSuccess,
          onSaveError,
          key
        );
      }
    },
    [form, formData, formLayoutData, onSaveError, formLayoutDispatch, onSaveSuccess]
  );

  return (
    <PopoverDropdown2
      target={target}
      isOpen={showMenu}
      togglePopover={setShowMenu}
      saveInputLabel="Defaults"
      options={formLayoutData[form.key].layouts}
      activeOption={formLayoutData[form.key].activeLayout}
      savingList={formLayoutData[form.key].savingList}
      onClickOption={handleSetActiveLayout}
      onSave={handleSaveLayout}
      defaultLabel="New Defaults"
      onRemove={removeLayout}
    />
  );
};
