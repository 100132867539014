import {
  Navbar,
  NavbarContent,
  NavbarDropDownContent,
  NavbarDropDownIndicator,
  NavbarIcon,
  NavbarIconContainer,
  NavbarItem,
  NavbarLink,
  NavbarLogo,
  NavbarRightSection,
  NotificationHub,
} from "@nef/core";
import { useKeycloak } from "@react-keycloak/web";
import { useLayoutContext } from "components/context";
import { LoadingIcon } from "components/loadingIcon";
import { network } from "components/settings";
import { INITIAL_DATA_MODEL, useUserContext } from "components/user";
import { getHeaders, getKeycloak } from "keycloak";
import { doFetchWrapper } from "network";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { RequestResult } from "wksConstants";
import { Bell } from ".";
import { colors } from "../../colors";
import { formatUrl } from "../../utils/js.utils";
import { Views } from "../../viewConstants";
import { useViewModelDispatch } from "../context";
import { useRefDataContext } from "../refData/context";
import { useSettingsContext } from "../settings";
import { UploadProcessing, UploadQueue } from "../upload";
import { PTRA } from "./alerts/ptra";

const LoadingNavbarItem = styled(NavbarItem)`
  cursor: ${props => (props.$isLoading ? "progress !important" : "inherit")};
`;

const TopBar = memo(() => {
  return (
    <Navbar doHideOnScroll={false}>
      <NavbarLogo as="a" />
      {getKeycloak().authenticated && <Secured />}
    </Navbar>
  );
});

export default TopBar;

const Secured = memo(() => {
  const [user] = useUserContext();
  const viewDispatch = useViewModelDispatch();
  const { keycloak, initialized } = useKeycloak();
  const [settingsData] = useSettingsContext();
  const [refData] = useRefDataContext();
  const { pathname } = useLocation();
  //TODO: jeff this seems like a forced way to use use effect?
  const [complianceClicks, setComplianceClicks] = useState(0);
  const [compLoading, setCompLoading] = useState(false);
  let history = useHistory();
  const handleLogOut = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  useEffect(() => {
    if (!keycloak.authenticated || !initialized || complianceClicks < 1) {
      return;
    }
    const getURL = async () => {
      try {
        await doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_ACT_WS, "compdb"),
          {
            method: "post",
            mode: "cors",
            headers: getHeaders(),
            body: JSON.stringify({ compdb: user.compdb }),
          },

          json => {
            window.open(json.compdbUrl, "Compliance Dashboard");
            setCompLoading(false);
          },
          () => {
            NotificationHub.send("danger", "Unable to start Compliance Dashboard", {
              subtitle: "Please try again later",
            });
            setCompLoading(false);
          }
        );
      } catch {}
    };
    getURL();
  }, [user.compdb, complianceClicks, keycloak, initialized]);

  const handleComplianceDash = useCallback(() => {
    if (user.compdb === null) {
      NotificationHub.send(
        "danger",
        "Your user profile is not assigned a Compliance Dashboard user id"
      );
      return;
    }
    setCompLoading(true);
    setComplianceClicks(complianceClicks + 1);
  }, [complianceClicks, user.compdb]);

  const handleSettingsClick = useCallback(
    e => {
      e.stopPropagation();
      if (user.userDataError && settingsData.isSettingsDisabled) {
        NotificationHub.send("danger", "Error retrieving user defaults", {
          subtitle: "Please report the issue to WorkX Help Desk",
        });
      } else {
        history.push("/settings");
      }
    },
    [history, settingsData.isSettingsDisabled, user.userDataError]
  );

  const handleDownloadUserGuide = useCallback(e => {
    e.stopPropagation();

    const onRejected = (/* error */) => {
      NotificationHub.send("danger", "Error retrieving User Guide", {
        subtitle: "Please report the issue to WorkX Help Desk",
      });
    };

    fetch(formatUrl(process.env.REACT_APP_URL_USER_WS, "getuserguide"), {
      headers: getHeaders(),
    })
      .then(res => res.blob(), onRejected)
      .then(blob => {
        const objectUrl = window.URL.createObjectURL(blob);
        const ele = document.createElement("a");
        ele.href = objectUrl;
        ele.target = "_blank";
        ele.download = "WorkX_User_Guide";
        ele.click();
        ele.remove();
      }, onRejected);
  }, []);

  const versionStyle = useMemo(() => {
    return {
      width: "100%",
      fontSize: ".8rem",
      paddingTop: ".5rem",
      marginTop: "7px",
      cursor: "default",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvents: "none",
    };
  }, []);

  const changeView = useCallback(
    view => () => {
      viewDispatch({
        type: "CHANGE_VIEW",
        payload: {
          history,
          view,
        },
      });
    },
    [viewDispatch, history]
  );

  const handleReportScan = useCallback(() => {
    changeView(!user.allowed.views[Views.REPORT] ? Views.SCAN : Views.REPORT)();
  }, [changeView, user.allowed.views]);

  const aboveVersionStyle = useMemo(
    () => ({
      borderBottom: `1px solid ${colors.lightgray}`,
    }),
    []
  );

  const handleOpenFixSpec = useCallback(() => {
    window.open(
      "https://nasdaqtrader.com/content/technicalsupport/specifications/TradingProducts/fixactspec.pdf"
    );
  }, []);

  return (
    <>
      <NavbarContent isOpen={true}>
        {(user.allowed.views[Views.REPORT] ||
          user.allowed.views[Views.SCAN] ||
          user.allowed.views[Views.REJECTS]) && (
          <NavbarItem
            active={[
              "/",
              `/${Views.REPORT}`,
              `/${Views.SCAN}`,
              `/${Views.REJECTS}`,
              `/${Views.UPLOAD}`,
              `/${Views.STATS}`,
              `/${Views.QUERY_TOOL}`,
              `/${Views.REFERENCE_DATA_CLEARING}`,
              `/${Views.REFERENCE_DATA_AGU}`,
              `/${Views.REFERENCE_DATA_CUSIP}`,
              `/${Views.PV_MEMBER_MONITOR}`,
              `/${Views.PV_FINRA_MONITOR}`,
            ].includes(pathname)}
            onClick={handleReportScan}
          >
            Trade Report
            <NavbarDropDownIndicator />
            <NavbarDropDownContent>
              <NavbarLink
                active={["/", `/${Views.REPORT}`, `/${Views.SCAN}`, `/${Views.REJECTS}`].includes(
                  pathname
                )}
                onClick={handleReportScan}
              >
                Report
              </NavbarLink>

              {user.allowed.views[Views.UPLOAD] ? (
                <NavbarLink
                  onClick={changeView(Views.UPLOAD)}
                  active={pathname === `/${Views.UPLOAD}`}
                >
                  Upload
                </NavbarLink>
              ) : (
                <></>
              )}

              {user.allowed.views[Views.QUERY_TOOL] ? (
                <NavbarLink
                  onClick={changeView(Views.QUERY_TOOL)}
                  active={pathname === `/${Views.QUERY_TOOL}`}
                >
                  Query History
                </NavbarLink>
              ) : (
                <></>
              )}

              {
                <NavbarLink
                  onClick={changeView(Views.REFERENCE_DATA_CLEARING)}
                  active={[
                    `/${Views.REFERENCE_DATA_CLEARING}`,
                    `/${Views.REFERENCE_DATA_AGU}`,
                    `/${Views.REFERENCE_DATA_CUSIP}`,
                  ].includes(pathname)}
                >
                  Reference Data
                </NavbarLink>
              }

              {user.allowed.views[Views.REAL_TIME_STATS] ? (
                <NavbarLink
                  onClick={changeView(Views.REAL_TIME_STATS)}
                  active={pathname === `/${Views.REAL_TIME_STATS}`}
                >
                  Real Time Stats
                </NavbarLink>
              ) : (
                <></>
              )}

              {user.allowed.views[Views.PV_MEMBER_MONITOR] ? (
                <NavbarLink
                  onClick={changeView(Views.PV_MEMBER_MONITOR)}
                  active={pathname === `/${Views.PV_MEMBER_MONITOR}`}
                >
                  PV Monitor
                </NavbarLink>
              ) : (
                <></>
              )}

              {user.allowed.views[Views.PV_FINRA_MONITOR] ? (
                <NavbarLink
                  onClick={changeView(Views.PV_FINRA_MONITOR)}
                  active={pathname === `/${Views.PV_FINRA_MONITOR}`}
                >
                  PV FINRA Monitor
                </NavbarLink>
              ) : (
                <></>
              )}
            </NavbarDropDownContent>
          </NavbarItem>
        )}

        {user.allowed.views[Views.POST_TRADE_RISK] && (
          <NavbarItem
            active={pathname === `/${Views.POST_TRADE_RISK}`}
            disabled={refData.isLimoDisabled}
            onClick={changeView(Views.POST_TRADE_RISK)}
          >
            Post - Trade Risk
          </NavbarItem>
        )}
        {user.allowed.views[Views.EQRC_MONITOR] && (
          <NavbarItem
            active={pathname === `/${Views.EQRC_MONITOR}`}
            onClick={changeView(Views.EQRC_MONITOR)}
            disabled={user.eqrcDataResult !== RequestResult.success}
          >
            Equity Risk Check
          </NavbarItem>
        )}
        {user.allowed.views[Views.COMPLIANCE_DB] && (
          <LoadingNavbarItem onClick={handleComplianceDash} $isLoading={compLoading}>
            <span style={{ paddingRight: "1rem" }}>Compliance Dashboard</span>
            <LoadingIcon isLoading={compLoading} />
          </LoadingNavbarItem>
        )}
        {user.allowed.views[Views.ORDER_ENTRY] && (
          <NavbarItem
            active={pathname === `/${Views.ORDER_ENTRY}`}
            onClick={changeView(Views.ORDER_ENTRY)}
            disabled={user[INITIAL_DATA_MODEL.configDataResult] !== RequestResult.success}
          >
            Order Entry
          </NavbarItem>
        )}
        {user.allowed.views[Views.TRADING] && (
          <NavbarItem active={pathname === `/${Views.TRADER}`} onClick={changeView(Views.TRADER)}>
            Trader
          </NavbarItem>
        )}
      </NavbarContent>

      <NavbarRightSection>
        {user.allowed.views[Views.UPLOAD] ? (
          <>
            <UploadProcessing />
            <UploadQueue id="trade-upload-queue" />
          </>
        ) : null}
        {user.allowed.views[Views.POST_TRADE_RISK] && <PTRA />}
        {user.allowed.views[Views.POST_TRADE_RISK] && (
          <Bell id="PTR-Limit-Setting-Reminder" tooltip="PTR Limit Setting Reminder" />
        )}
        <NavbarIconContainer href="#">
          <NavbarIcon iconClassName="fa-user" />
          <NavbarDropDownContent>
            <NavbarLink
              style={{
                borderBottom: `1px solid ${colors.lightgray}`,
                cursor: "default",
                pointerEvents: "none",
              }}
            >
              <span>
                <NavbarIcon iconClassName="fa-user" />
                Welcome, {user.username}
              </span>
            </NavbarLink>
            {Object.keys(user.entitlements).length > 0 ? (
              <NavbarLink
                onClick={/*settingsData.isSettingsDisabled ? undefined : */ handleSettingsClick}
                // disabled={settingsData.isSettingsDisabled}
              >
                <span>
                  <NavbarIcon iconClassName="fa-cog" />
                  Settings
                </span>
              </NavbarLink>
            ) : (
              <></>
            )}

            <NavChartToggle />

            <NavbarLink onClick={handleDownloadUserGuide}>
              <span>
                <NavbarIcon iconClassName="fa-question-circle" />
                User Guide
              </span>
            </NavbarLink>
            <NavbarLink onClick={handleOpenFixSpec}>
              <span>
                <NavbarIcon iconClassName="fa-file-alt" />
                TRF Fix Specification
              </span>
            </NavbarLink>
            <NavbarLink as="a" onClick={handleLogOut} style={aboveVersionStyle}>
              <span>
                <NavbarIcon iconClassName="fa-door-open" /> Logout
              </span>
            </NavbarLink>
            <NavbarItem style={versionStyle}>Version {process.env.REACT_APP_VERSION}</NavbarItem>
          </NavbarDropDownContent>
        </NavbarIconContainer>
      </NavbarRightSection>
    </>
  );
});

const noop = () => {};
const NavChartToggle = () => {
  const [user] = useUserContext();
  const [viewModel, dispatch] = useLayoutContext();
  // const [data] = useViewModelContext();
  const { pathname } = useLocation();

  const onClick = useCallback(() => {
    if (user.userDataError && viewModel.isToggleRightDisabled) {
      NotificationHub.send("danger", "Error retrieving user defaults", {
        subtitle: "Please report the issue to WorkX Help Desk",
      });
    }
    if (
      // user.userDataError &&
      viewModel.isToggleRightDisabled // ||
      //ldata.topLevelView === "ST"
    ) {
      return;
    }
    dispatch({ type: "TOGGLE_RIGHT" });
    network().writeSettings(
      {
        data: JSON.stringify({ isVisible: !viewModel.isRightOpen }),
        name: "rightPanelVisibility",
        type: "rightPanelVisibility",
      },
      noop,
      noop
    );
  }, [
    viewModel.isRightOpen,
    dispatch,
    // data.topLevelView,
    viewModel.isToggleRightDisabled,
    user.userDataError,
  ]);
  const toggleState = viewModel.isRightOpen ? "Hide" : "Show";
  return (
    <>
      {user.allowed.views[Views.RIGHT] ? (
        <>
          <NavbarLink
            onClick={viewModel.isToggleRightDisabled ? undefined : onClick}
            to={pathname}
            id={"nav-charts"}
            disabled={viewModel.isToggleRightDisabled}
          >
            <span>
              <NavbarIcon iconClassName="fa-chart-bar" />
              {`${toggleState} Right Hand Panel`}
            </span>
          </NavbarLink>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
