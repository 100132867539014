import { convertDataToAPI, convertSampleAPIData } from "components/standardTable";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "../../utils/js.utils";

const urls = {
  myTrades: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "mytrades"),
  report: formatUrl(process.env.REACT_APP_URL_ACT_WS, "report"),
  scan: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "trades"),
  rejects: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "rejects"),
  repair: formatUrl(process.env.REACT_APP_URL_ACT_WS, "repair"),
  modify: formatUrl(process.env.REACT_APP_URL_ACT_WS, "modify"),
  recap: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "tradesForRecap"),
  rdClearing: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "getClearRelationShipScan"),
  rdAGU: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "getAguQsrScan"),
  rdCUSIP: formatUrl(process.env.REACT_APP_URL_QUERY_WS, "getSecurityIssues"),
};

const genericScanRequest = async (
  url,
  fieldData,
  callback,
  errorCallback,
  abortCallback,
  abortController,
  maxTableSize,
  onOk,
  userMpids
) => {
  try {
    await doFetchWrapper(
      url,
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify(convertDataToAPI(fieldData)[0]),
        signal: abortController?.signal,
      },

      json => {
        let tableData = [],
          truncated = [];
        if (Array.isArray(json)) {
          tableData = json.slice(0, maxTableSize);
          truncated = json.slice(maxTableSize, json.length);
        }

        callback(tableData, truncated, userMpids);
      },
      errorCallback,
      abortCallback,
      onOk
    );
  } catch (error) {
    console.error(error);
    errorCallback();
  }
};
export const network = (userMpids = [], token = "") => ({
  firstReportRender: async (fieldData, callback, errorCallback) => {
    await doFetchWrapper(
      urls.myTrades,
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify(fieldData),
      },

      json => {
        callback(convertSampleAPIData(json, userMpids));
      },
      e => {
        errorCallback(e);
      }
    );
  },

  doReport: (fieldData, callback, errorCallback) => {
    try {
      doFetchWrapper(
        urls.report,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(convertDataToAPI(fieldData)[0]),
        },

        json => {
          callback(convertSampleAPIData([json], userMpids));
        },
        e => {
          errorCallback(e);
        }
      );
    } catch (e) {
      errorCallback(e);
    }
  },

  doTRScan: async (
    fieldData,
    callback,
    errorCallback,
    abortCallback,
    abortController,
    maxTableSize,

    onOk
  ) => {
    genericScanRequest(
      urls.scan,
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      maxTableSize,
      onOk
    );
  },
  doRDClearing: async (
    fieldData,
    callback,
    errorCallback,
    abortCallback,
    abortController,
    maxTableSize,
    onOk
  ) => {
    genericScanRequest(
      urls.rdClearing,
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      maxTableSize,
      onOk
    );
  },
  doRDAGU: async (
    fieldData,
    callback,
    errorCallback,
    abortCallback,
    abortController,
    maxTableSize,
    onOk
  ) => {
    genericScanRequest(
      urls.rdAGU,
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      maxTableSize,
      onOk
    );
  },
  doRDCUSIP: async (
    fieldData,
    callback,
    errorCallback,
    abortCallback,
    abortController,
    maxTableSize,
    onOk
  ) => {
    genericScanRequest(
      urls.rdCUSIP,
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      maxTableSize,
      onOk
    );
  },

  doTRRejects: async (
    fieldData,
    callback,
    errorCallback,
    abortCallback,
    abortController,
    maxTableSize,
    onOk
  ) => {
    genericScanRequest(
      urls.rejects,
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      maxTableSize,
      onOk
    );
  },

  doRepair: async (r, callback, errorCallback) => {
    const id = r.id;
    try {
      await doFetchWrapper(
        urls.repair,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(convertDataToAPI([r], userMpids)[0]),
        },

        json => {
          const converted = convertSampleAPIData([json], userMpids)[0];
          callback(converted, id);
        },
        e => {
          errorCallback(e, id);
        }
      );
    } catch (error) {
      console.error(error);
      errorCallback(error, id);
    }
  },

  doModify: async (fieldData, callback, errorCallback) => {
    const originalWksID = fieldData[0].workstationId;
    const originalControlNum = fieldData[0].controlNum;
    const converted = convertDataToAPI(fieldData, userMpids);

    converted[1].converted = true;

    const data = {
      newTrade: { ...converted[0] },
      reversal: converted[1],
    };

    try {
      await doFetchWrapper(
        urls.modify,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(data),
        },

        json => {
          callback(convertSampleAPIData(json, userMpids), originalWksID, originalControlNum);
        },
        json => {
          errorCallback(json, originalWksID, originalControlNum);
        }
      );
    } catch {
      errorCallback();
    }
  },

  doCopy: async (fieldData, callback, errorCallback) => {
    try {
      await doFetchWrapper(
        urls.report,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(convertDataToAPI(fieldData)[0]),
        },
        json => {
          callback(convertSampleAPIData([json], userMpids));
        },
        errorCallback
      );
    } catch {
      errorCallback();
    }
  },

  doRecap: async (idList, wksIdList, callback, errorCallback, abortController) => {
    try {
      await doFetchWrapper(
        urls.recap,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify({ idList, wksIdList }),
          signal: abortController.signal,
        },
        json => {
          callback(convertSampleAPIData(json, userMpids));
        },
        errorCallback
      );
    } catch {
      errorCallback();
    }
  },
});
