import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { Submit, useFormContext, useFormDispatch } from "components/form";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  getSelectedRow,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { StandardTables } from "wksConstants";
import { convertUploadTableToForm, network } from ".";
import { handleCancel } from "components/reportScan/submits";
import { useViewModelContext, useViewModelDispatch } from "components/context";

const accentName = "secondary";
const buttonText = "Repair";
export const UploadRepairSubmit = ({ table, form }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const viewDispatch = useViewModelDispatch();
  const [viewData] = useViewModelContext();
  const history = useHistory();

  const onCancel = useCallback(() => {
    handleCancel({ viewDispatch, formDispatch, form, history });
  }, [viewDispatch, formDispatch, form, history]);

  const updateFormForRepair = useCallback(() => {
    standardTableDispatch({
      type: "SET_LOADING",
      payload: {
        table: StandardTables.UPLOAD_ERRORS,
      },
    });
    const original = getSelectedRow(standardTableData[StandardTables.UPLOAD_ERRORS]);
    const tradeCallback = (data, uploadDetailId) => {
      viewDispatch({
        type: "ENABLE_FORM",
        payload: { form: Forms.UPLOAD_REPAIR },
      });
      const fields = {
        ...convertUploadTableToForm(data),
        id: data.id,
        workstationId: data.workstationID,
        userId: data.workstationUserId?.value,
        uploadDetailId,
        objVer: data.objver,
      };
      formDispatch({
        type: "SET_FORM_VALUES",
        payload: {
          form: Forms.UPLOAD_REPAIR,
          fields,
        },
      });
      standardTableDispatch({
        type: "SET_NOT_LOADING",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
        },
      });
    };
    const tradeErrorCallback = reject => {
      let message = "Something went wrong when fetching details for the selected trade.";
      if (reject?.message) {
        message = reject.message;
      }
      NotificationHub.send("danger", message);
      standardTableDispatch({
        type: "SET_NOT_LOADING",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
        },
      });
    };
    let data = original;
    if (!original.workstationId && !original.workstationID) {
      data = JSON.parse(original.data);
    }
    network().getRejectedTrade(data, tradeCallback, tradeErrorCallback);
  }, [standardTableData, formDispatch, viewDispatch, standardTableDispatch]);

  const handleSubmit = useCallback(() => {
    standardTableDispatch({
      type: "SET_LOADING",
      payload: {
        table: StandardTables.UPLOAD_ERRORS,
      },
    });
    formDispatch([
      { type: "SET_IS_LOADING", payload: { form: Forms.UPLOAD_REPAIR } },
      {
        type: "ALLOW_SHOW_ERROR",
        payload: { form: Forms.UPLOAD_REPAIR, allowShowError: true },
      },
    ]);
    // we have errors, prevent submission of data
    if (formData[Forms.UPLOAD_REPAIR.key].totalFormErrors > 0) {
      formDispatch([
        {
          type: "SET_GLOBAL_ERROR",
          payload: {
            message: "Validation errors detected",
            form: Forms.UPLOAD_REPAIR,
          },
        },
        { type: "SET_NOT_IS_LOADING", payload: { form: Forms.UPLOAD_REPAIR } },
      ]);
      standardTableDispatch({
        type: "SET_NOT_LOADING",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
        },
      });
      return;
    } else {
      formDispatch({
        type: "SET_GLOBAL_ERROR",
        payload: {
          message: "",
          form: Forms.UPLOAD_REPAIR,
        },
      });
    }

    const repairCallback = (r, originalWksID) => {
      const { trade, upload, uploadRecord } = r;
      const formActions = [{ type: "SET_NOT_IS_LOADING", payload: { form: Forms.UPLOAD_REPAIR } }];
      if (trade.rejectText) {
        formActions.push({
          type: "SET_GLOBAL_ERROR",
          payload: {
            message: trade.rejectText,
            form: Forms.UPLOAD_REPAIR,
          },
        });
      }

      const tableActions = [
        {
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: {
            table: StandardTables.UPLOAD_FILES,
            row: upload,
            idField: "id",
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_SUCCESS,
          },
        },
      ];

      const tableRecord = Object.assign({}, uploadRecord, JSON.parse(uploadRecord.data));
      if (tableRecord.status === "A") {
        tableActions.push(
          {
            type: "DELETE_ROW_BY_VALUE",
            payload: {
              table: StandardTables.UPLOAD_ERRORS,
              key: "workstationID",
              value: tableRecord.key,
            },
          },
          {
            type: "ADD_TABLE_DATA",
            payload: { table: StandardTables.UPLOAD_SUCCESS, data: [tableRecord] },
          }
        );
      } else {
        tableActions.push({
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
            row: tableRecord,
            idField: "workstationID",
          },
        });
      }
      standardTableDispatch(tableActions);

      if (trade.tradeStatus !== "-") {
        formActions.push({ type: "RESET_FORM", payload: { form: Forms.UPLOAD_REPAIR } });
        NotificationHub.send("success", "Repair successful");

        viewDispatch({
          type: "DISABLE_FORM",
          payload: { form: Forms.UPLOAD_REPAIR },
        });
      } else {
        NotificationHub.send("danger", "Repair did not complete");
        updateFormForRepair();
      }
      formDispatch(formActions);
    };

    const errorCallback = reject => {
      NotificationHub.send("danger", "Repair did not complete");
      formDispatch({ type: "SET_NOT_IS_LOADING", payload: { form: Forms.UPLOAD_REPAIR } });
      standardTableDispatch([
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_SUCCESS,
          },
        },
      ]);
      updateFormForRepair();
    };

    const fieldData = [{ ...formData[Forms.UPLOAD_REPAIR.key].fields }];

    const { uploadDetailId } = formData[Forms.UPLOAD_REPAIR.key].fields;
    network().doRepair(fieldData, uploadDetailId, repairCallback, errorCallback);
  }, [
    formDispatch,
    standardTableDispatch,
    standardTableData,
    formData,
    viewDispatch,
    updateFormForRepair,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDisabled={standardTableData[table]?.isLoading || viewData[`${form.key}Disabled`]}
      isLoading={formData[form.key]?.isLoading}
      errors={Object.keys(formData[form.key].totalFormErrors).length}
    />
  );
};
