import {
  Box,
  Button,
  CircleButton,
  CSSGrid,
  FontAwesomeIcon,
  Header,
  NotificationHub,
  Popover,
  PopoverBody,
} from "@nef/core";
import { FieldNames, tableActions } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import {
  eqrcRuleTypeMap,
  TableButtonAction,
  TableButtonLabel,
  translateSettlementWithT1RegularFlag,
} from "wksConstants";
import { ApiResponseNames } from "../fields";
import { doAction, breakProxy } from "./network";
import { useStandardTableContext } from "./standardTableContext";
import {
  AcceptMenu,
  AltHeader,
  BreakMenu,
  EditLimitsMenu,
  ExportLimitsMenu,
  MenuHeader,
  EqrcEditMenu,
  EqrcHistoryMenu,
  RashCancelMenu,
} from "./tableActionMenus";
import { notifyFail, notifyInvalid, notifySuccess } from "./tableButton.notifiers";
import useCheckers, { useToPayload } from "./tableButton.rowCheckers";
import { errorCallbackInner, successCallbackInner } from "./tableButtons.callbacks";
import styled from "styled-components";
import { isReversal } from "utils/js.utils";

const StyledPopover = styled(Popover)`
  ${props => `
    ${
      props.removeMaxHeight
        ? `
      max-height: none !important;
      height:auto;
    `
        : ""
    }
  `}
`;

const TableButtonFn = ({
  table,
  text,
  actionId,
  icon,
  canConfirm,
  allowConfirm,
  disabled,
  disabledMessage,
  id,
  style,
  selectedRows,
  onClick,
  allowMenuOverride,
  loading,
  children,
  requireSelect,
  header,
  onCloseConfirm,
}) => {
  const [open, setOpen] = useState(false);
  const [data, dispatch] = useStandardTableContext();
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [user] = useUserContext();

  const checkers = useCheckers();
  const toPayload = useToPayload();

  const openMenu = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [selectedRows]);

  useEffect(() => {
    if (actionId === TableButtonAction.ACCEPT) {
      formDispatch({
        type: "INIT_FORM_VALIDATION",
        payload: {
          entitlements: user.entitlements,
          form: Forms.ST_ACCEPT,
          fields: [tableActions.acceptA.capacity, tableActions.acceptB.contraShortSaleInd],
        },
      });
    }
  }, []); //form validation - just once

  const rows = useMemo(() => {
    if (!data[table]?.internalSelectedRows) {
      return { valid: [], invalid: [], excluded: 0, hasBuy: false };
    }
    const selectedRows = Object.keys(data[table]?.internalSelectedRows);
    if (selectedRows.length <= 0) {
      return { valid: [], invalid: [], excluded: 0, hasBuy: false };
    }

    const rowsAcc = Object.keys(data[table]?.internalSelectedRows).reduce(
      (acc, curr) => {
        const row = { ...data[table].data[curr] };
        // guard against selecting rows that don't exist
        if (Object.getOwnPropertyNames(row).length === 0) {
          return acc;
        }

        if (row?.side?.value === "B") {
          acc.hasBuy = true;
        }
        const checkedRow = checkers(row, actionId);
        if (checkedRow === undefined) {
          return acc;
        }

        checkedRow.hasOwnProperty("valid")
          ? acc.valid.push(toPayload(checkedRow.valid, actionId))
          : acc.invalid.push(toPayload(checkedRow.invalid, actionId)) && acc.excluded++;
        return acc;
      },
      { valid: [], invalid: [], excluded: 0, hasBuy: false }
    );
    return rowsAcc;
  }, [data, table, actionId, checkers, toPayload]);

  const invalidSelectionMessage = useMemo(() => {
    if (rows.valid.length === 0 && requireSelect) {
      return "Invalid row(s) selected.";
    } else {
      return null;
    }
  }, [rows, requireSelect]);

  const errorCallback = useCallback(
    (e, actionId = "", id, controlNum) => {
      if (Array.isArray(e?.errorMessages) && e.errorMessages.length > 0) {
        e.message = e.errorMessages[0];
      }
      rows.valid.forEach(r => {
        const error = e;
        errorCallbackInner({
          r,
          dispatch,
          error,
          id,
          controlNum,
          table,
          actionId,
        });
      });
    },
    [dispatch, table, rows]
  );

  const successCallback = useCallback(
    (responses, id, controlNum) => {
      if (!Array.isArray(responses)) {
        responses = [responses];
      }
      successCallbackInner({
        responses,
        id,
        controlNum,
        dispatch,
        actionId,
        table,
      });
    },
    [dispatch, actionId, table]
  );

  const onClick2 = useCallback(async () => {
    if (actionId === TableButtonAction.ACCEPT) {
      const formActions = [
        {
          type: "ALLOW_SHOW_ERROR",
          payload: { form: Forms.ST_ACCEPT, allowShowError: true },
        },
      ];

      // we have errors, prevent submission of data
      if (formData[Forms.ST_ACCEPT.key].totalFormErrors > 0) {
        formActions.push({
          type: "SET_GLOBAL_ERROR",
          payload: {
            message: "Validation errors detected",
            form: Forms.ST_ACCEPT,
          },
        });
        formDispatch(formActions);
        Promise.resolve(false);
        return false;
      }
      formDispatch(formActions);
    }
    const tableDispatches = [];
    let isTradeReversal = false;
    rows.valid.forEach((r, idx) => {
      r[FieldNames.settlement] = translateSettlementWithT1RegularFlag(
        r[FieldNames.settlement],
        r[FieldNames.sellerDays],
        user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.t1RegularSettlement]
      );

      if (actionId === TableButtonAction.REVERSE && isReversal(r[ApiResponseNames.reversalFlag])) {
        isTradeReversal = true;
        delete rows.valid[idx];
      } else {
        // set this row to be loading
        const key = r.controlNum
          ? { value: r.controlNum, key: "controlNum" }
          : { value: r.id, key: "id" };

        tableDispatches.push({
          type: "UPDATE_CELLS",
          payload: {
            table,
            key,
            values: [
              { value: "loading", key: "status" },
              { key: "statusText", value: undefined },
            ],
          },
        });
      }
    });

    if (isTradeReversal) {
      NotificationHub.send("warning", "Reversal action is not supported for reversal trades");
    }

    tableDispatches.push({ type: "DESELECT_ALL_ROWS", payload: { table } });

    dispatch(tableDispatches);

    if (rows.invalid.length > 0) {
      notifyInvalid(rows.invalid, rows.excluded);
    }

    openMenu(); // the micro action

    let result;
    if (actionId === TableButtonAction.BREAK) {
      result = await breakProxy(rows.valid, successCallback, errorCallback, actionId);
    } else {
      result = await doAction(actionId, rows.valid, successCallback, errorCallback, actionId);
    }

    const { success, fail } = result;

    if (success !== "error" && fail !== "error") {
      if (fail.length > 0 || Object.keys(fail.badRequestCount).length > 0) {
        notifyFail(rows.valid, fail, actionId);
      }

      if (success.length > 0) {
        notifySuccess(rows.valid, success, actionId);
      }
    }
  }, [
    openMenu,
    table,
    formDispatch,
    dispatch,
    actionId,
    formData,
    successCallback,
    errorCallback,
    rows,
  ]);

  const disabledMemo = useMemo(() => {
    if (disabled && disabledMessage) {
      return { message: disabledMessage };
    }
    if (invalidSelectionMessage) {
      return { message: invalidSelectionMessage };
    }
    return disabled;
  }, [disabled, disabledMessage, invalidSelectionMessage]);

  // TODO: this is garbage
  // We need to make the disabled descision, and then decide if the action
  // should occur from the menu or from the button
  // and decide if we are using the passed action or not
  // :: hot mess emjoi ::
  // let realOnClicked = onClick ? onClick : onClick2;

  const realOnClicked = useMemo(() => {
    return e => {
      if (onClick) {
        onClick(e, rows);
        setOpen(false);
      } else {
        onClick2(e, rows).then(v => {
          if (v) {
            setOpen(!v);
          }
        });
      }
    };
  }, [onClick, onClick2, rows]);

  let buttonOnClick;
  if (!disabled) {
    buttonOnClick = realOnClicked;
    if (canConfirm && allowConfirm) {
      buttonOnClick = openMenu;
    } else if (canConfirm && !allowConfirm && allowMenuOverride === false) {
      buttonOnClick = openMenu;
    }
  }

  const headerMemo = useMemo(
    () => header && header({ validRows: rows.valid, invalidRowCount: rows.excluded, text }),
    [header, rows.excluded, rows.valid, text]
  );

  if (!table) {
    return <></>;
  }

  return (
    <>
      <Box marginLeft={3} key={actionId} id={id} style={style}>
        <CircleButton
          outline
          color="primary"
          subtitle={text}
          id={actionId}
          disabled={disabledMemo}
          isLoading={loading}
          onClick={buttonOnClick}
        >
          {icon ? <FontAwesomeIcon iconClassName={`fa-${icon}`} /> : children}
        </CircleButton>
      </Box>
      {canConfirm && (allowConfirm || allowMenuOverride === false) && (
        <ButtonMenu
          onClick={realOnClicked}
          isOpen={open}
          toggle={openMenu}
          text={text}
          actionId={actionId}
          validRows={rows.valid}
          excludedRows={rows.excluded}
          hasBuy={rows.hasBuy}
          requireSelect={requireSelect}
          header={headerMemo}
          table={table}
          setOpen={setOpen}
          onClose={onCloseConfirm}
        />
      )}
    </>
  );
};

const TableButton = TableButtonFn;
export default memo(TableButton);

const ButtonMenu = memo(
  ({
    isOpen,
    toggle,
    text,
    actionId,
    validRows,
    excludedRows,
    hasBuy,
    requireSelect,
    onClick,
    selectedRows,
    header,
    table,
    setOpen,
    onClose,
  }) => {
    const [hasBeenOpen, setHasBeenOpen] = useState(false);

    useEffect(() => {
      if (typeof onClose === "function") {
        if (hasBeenOpen && !isOpen) {
          onClose();
        } else if (!hasBeenOpen && isOpen) {
          setHasBeenOpen(true);
        }
      }
    }, [onClose, isOpen, hasBeenOpen]);

    switch (actionId) {
      case TableButtonAction.EQRC_AUDIT: {
        return (
          <EqrcHistoryMenu
            rows={validRows}
            isOpen={isOpen}
            target={actionId}
            text={text}
            validRows={validRows}
            requireSelect={requireSelect}
            onClick={onClick}
            toggle={toggle}
            form={Forms.EQRC_AUDIT_MENU}
            isAlertHistory={false}
          >
            <Header size={3}>Audit {eqrcRuleTypeMap[table]?.label} Rule</Header>
          </EqrcHistoryMenu>
        );
      }
      case TableButtonAction.EQRC_HISTORY: {
        return (
          <EqrcHistoryMenu
            rows={validRows}
            isOpen={isOpen}
            target={actionId}
            text={text}
            validRows={validRows}
            requireSelect={requireSelect}
            onClick={onClick}
            toggle={toggle}
            form={Forms.EQRC_HISTORY_MENU}
            isAlertHistory={true}
          >
            <Header size={3}>Export Alert History</Header>
          </EqrcHistoryMenu>
        );
      }
      case TableButtonAction.RASH_CANCEL: {
        return (
          <RashCancelMenu
            rows={validRows}
            isOpen={isOpen}
            target={actionId}
            text={text}
            requireSelect={requireSelect}
            onClick={onClick}
            setOpen={setOpen}
          >
            <Header size={3}>Cancel Order Shares</Header>
          </RashCancelMenu>
        );
      }
      default:
        return (
          <StyledPopover
            isOpen={isOpen}
            target={actionId}
            placement={"bottom"}
            width={325}
            maxContent={false}
            removeMaxHeight={text === TableButtonLabel.EDIT_LIMITS}
          >
            <PopoverBody>
              {header}
              {requireSelect && validRows.length <= 0 && <AltHeader text={text} />}
              {(!requireSelect || validRows.length > 0) && (
                <MenuHeader text={text} validRows={validRows} invalidRows={excludedRows} />
              )}
              {actionId === TableButtonAction.EXPORT_LIMITS && <ExportLimitsMenu />}
              {actionId === TableButtonAction.EDIT_LIMITS && <EditLimitsMenu row={validRows[0]} />}
              {actionId === TableButtonAction.ACCEPT && validRows.length > 0 && (
                <AcceptMenu hasBuy={hasBuy} selectedRows={selectedRows} />
              )}
              {actionId === TableButtonAction.BREAK && validRows.length > 0 && (
                <BreakMenu rows={validRows} />
              )}
              {actionId === TableButtonAction.EQRC_EDIT && <EqrcEditMenu />}
              <CSSGrid cols="1fr 1fr" gap="0 1rem">
                <Button disabled={requireSelect && validRows.length <= 0} onClick={onClick}>
                  {text}
                </Button>
                <Button onClick={toggle} outline>
                  Discard
                </Button>
              </CSSGrid>
            </PopoverBody>
          </StyledPopover>
        );
    }
  }
);
