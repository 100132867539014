import { StandardTables } from "wksConstants";
export * from "./report";
export * from "./copy";
export * from "./modify";
export * from "./repair";
export * from "./genericScan";

export const setFormLoading = (formDispatch, form) => {
  formDispatch({ type: "SET_IS_LOADING", payload: { form } });
};

export const setTableLoading = (standardTableDispatch, table) => {
  standardTableDispatch({
    type: "SET_IS_LOADING",
    payload: { table: table, isLoading: true },
  });
};

export const setCellsLoading = (standardTableDispatch, table, fieldData) => {
  const key = fieldData[0].controlNum
    ? { value: fieldData[0].controlNum, key: "controlNum" }
    : { value: fieldData[0].id, key: "id" };

  standardTableDispatch({
    type: "UPDATE_CELLS",
    payload: {
      table: StandardTables[/report/i.test(table) ? "TR_REPORT" : "TR_SCAN"],
      key,
      values: [
        { value: "loading", key: "status" },
        { key: "statusText", value: undefined },
      ],
    },
  });
};

export const handleAbortCallback = args => {
  const { standardTableDispatch, formDispatch, table, form } = args;
  standardTableDispatch({
    type: "SET_IS_LOADING",
    payload: { table, isLoading: false },
  });
  formDispatch({
    type: "SET_NOT_IS_LOADING",
    payload: { form },
  });
};

export const handleOk = args => {
  const { formDispatch, form } = args;
  formDispatch({
    type: "SET_NOT_IS_LOADING",
    payload: { form },
  });
};

export const handleCancel = ({ viewDispatch, formDispatch, form, history }) => {
  viewDispatch([
    {
      type: "CANCEL_FORM",
      payload: { history },
    },
    { type: "DISABLE_FORM", payload: { form } },
  ]);
  formDispatch({
    type: "RESET_FORM",
    payload: { form },
  });
};
