import { Flex, NotificationHub, Section } from "@nef/core";
import { FieldLoop, stats } from "components/fields";
import { FieldNames, Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { Status } from "../../wksConstants";
import { useStatsContext } from "components/stats";
import { NarrowBarItem } from "./narrowBarItem";
import { Box } from "@nef/core/lib";
import { LimoLVCIndicator } from "../limitMonitor";
import { WarningSection } from "./warningStyled";
import styled from "styled-components";
import { FlexNarrowBar } from "components/styled";
import { getColor } from "@nef/style-utils";

const FlexNarrowBarTop = styled(FlexNarrowBar)`
  & > div > div {
    padding-right: 1.5rem;
    border-right: 1px ${getColor("gray", 300)} solid;
    &:last-child {
      border-width: 0;
    }

    & > div {
      margin: 0.75rem;
    }
  }
  & p,
  h1 {
    margin: 0 0.355rem;
  }
`;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledBox = styled(Box)`
  margin-bottom: 1rem;
`;

const NarrowBarTop = () => {
  const [data, statsDispatch] = useStatsContext();
  const [user] = useUserContext();
  const [formData, formDispatch] = useFormContext();

  const augmentOnChange = useCallback(
    payload => {
      const statsActions = [
        {
          type: "SET_SHOULD_ABORT",
          payload: true,
        },
      ];
      switch (payload?.field) {
        case FieldNames.mpid:
          if (Array.isArray(payload?.value) && payload.value.length <= data.maxNumStatsMpid) {
            statsActions.push({
              type: "FORM_CHANGE",
              payload: payload,
            });
          } else {
            formDispatch({
              type: "UPDATE_FORM_VALUE",
              payload: {
                form: Forms.ST_MIDDLE,
                field: FieldNames.mpid,
                value: payload.value.slice(0, payload.value.length - 1),
                entitlements: user.entitlements,
              },
            });
            NotificationHub.send(
              "danger",
              `Cannot select more than ${data.maxNumStatsMpid} MPIDs for Real Time Stats`
            );
          }
          break;
        case FieldNames.trf:
        default:
          statsActions.push({
            type: "FORM_CHANGE",
            payload: payload,
          });
          break;
      }

      if (payload.value.length === 0) {
        statsActions.push({ type: "SET_FEED_STATUS", payload: Status.NO_STATUS });
      }

      statsDispatch(statsActions);
    },
    [statsDispatch, data.maxNumStatsMpid, formDispatch, user.entitlements]
  );

  const augmentOnCreate = useCallback(
    payload => {
      statsDispatch({
        type: "ADD_OPTION",
        payload,
      });
    },
    [statsDispatch]
  );

  const noop = useCallback(() => {}, []);
  const mpids = data.requestPayload.mpids;
  const trf = data.requestPayload.trf;

  useEffect(() => {
    if (mpids.length && trf.length) {
      const data = JSON.stringify({
        [FieldNames.mpid]: formData[Forms.ST_MIDDLE.key].fields[FieldNames.mpid],
        [FieldNames.trf]: formData[Forms.ST_MIDDLE.key].fields[FieldNames.trf],
      });
      network().writeSettings(
        {
          data,
          name: "statsSelection",
          type: "statsSelection",
        },
        noop, // onSaveSuccess,
        noop // onSaveError
      );
    }
  }, [mpids, trf, noop, formData]);

  const portalRef = useRef();
  return (
    <StyledFlex flexDirection="column">
      <StyledFlex justifyContent="center" alignItems="center">
        <FieldLoop
          form={Forms.ST_MIDDLE}
          fields={stats}
          augmentOnChange={augmentOnChange}
          augmentOnCreate={augmentOnCreate}
          showLabel={false}
          portalRef={portalRef}
        />
        <StyledBox>
          <LimoLVCIndicator id="real-time-stats-data-feed-status" status={data.feedStatus} />
        </StyledBox>
      </StyledFlex>
      <WarningSection $isWarning={data.hasData && data.feedStatus === Status.ERROR}>
        <FlexNarrowBarTop justifyContent="space-between">
          <Flex style={{ margin: "auto" }}>
            <Flex role="list" justifyContent="center" alignItems="center">
              {Object.entries(data.narrowBarTop).map(([key, data]) => {
                return (
                  <NarrowBarItem
                    text={key}
                    value={data.value}
                    statType={data.statType}
                    id={`narrowBarTop_${key}`}
                    key={`narrowBarTop_${key}`}
                  />
                );
              })}
            </Flex>
          </Flex>
        </FlexNarrowBarTop>
      </WarningSection>
    </StyledFlex>
  );
};
export default NarrowBarTop;
