import React, { useCallback, useEffect, useMemo } from "react";
import { useLayoutContext, useViewModelContext } from "../context";
import { Section, SectionBody, SectionHeader, Box } from "@nef/core";
import { network } from "components/settings";
import { RightStats, RightLimit } from "./";
import { SelectOptions, WorkXSelect } from "../fields";
import { Views } from "../../viewConstants";
import { useUserContext } from "components/user";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const RISK = () => SelectOptions.rightViewsContainer.risk;
const STATS = () => SelectOptions.rightViewsContainer.stats;
const selectStyles = { width: "105%", marginRight: 0 };

const HeaderSelect = styled(WorkXSelect)`
  font-size: 17.5px;
`;

const RightBox = styled(Box)`
  overflow-y: scroll;
  & p,
  h4 {
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
  }

  & h4 {
    margin-right: 0.5rem;
    text-align: right;
    width: 6ch;
  }
  & h4,
  h4 span {
    font-size: 1rem;
    font-family: "Source Sans Pro";
  }
`;

const Right = ({ isOpen }) => {
  const [user] = useUserContext();
  const [context] = useViewModelContext();
  const [layoutData, layoutDispatch] = useLayoutContext();

  const display = useMemo(() => {
    return { display: `${isOpen ? "block" : "none"}` };
  }, [isOpen]);

  const noop = useCallback(() => {}, []);

  const onInputChange = useCallback(
    ({ value }) => {
      layoutDispatch({
        type: "SET_RIGHT_VIEW",
        payload: { view: value },
      });

      const data = JSON.stringify({
        rightViewSelection: value,
      });

      network().writeSettings(
        {
          data,
          name: "rightViewSelection",
          type: "rightViewSelection",
        },
        noop, // onSaveSuccess,
        noop // onSaveError
      );
    },
    [layoutDispatch, noop]
  );

  // 'hide right side' checks
  useEffect(() => {
    // no right panel entitlements
    if (!user.allowed.views[Views.RIGHT] || !layoutData.isRightOpen) {
      layoutDispatch({
        type: "SET_HIDE_RIGHT",
      });

      return <></>;
    } else if (context.view === Views.REPORT) {
      if (user.allowed.views[Views.REAL_TIME_STATS]) {
        layoutDispatch({
          type: "SET_HIDE_RIGHT",
          payload: false,
        });
      } else {
        layoutDispatch({
          type: "SET_HIDE_RIGHT",
        });
      }
    } else if (context.view === Views.REAL_TIME_STATS) {
      if (user.allowed.views[Views.POST_TRADE_RISK]) {
        layoutDispatch({
          type: "SET_RIGHT_VIEW",
          payload: { view: RISK() },
        });
        layoutDispatch({
          type: "SET_HIDE_RIGHT",
          payload: false,
        });
      } else {
        layoutDispatch({
          type: "SET_HIDE_RIGHT",
        });
      }
    } else {
      layoutDispatch({
        type: "SET_HIDE_RIGHT",
        payload: false,
      });
    }
  }, [
    layoutData.isRightOpen,
    context.topLevelView,
    layoutData.rightView,
    user.entitlements,
    layoutDispatch,
    user.allowed.views,
    context.view,
  ]);

  const view = useCallback(() => {
    switch (layoutData.rightView) {
      case STATS(): {
        return <RightStats isOpen={isOpen} />;
      }
      case RISK(): {
        return <RightLimit isOpen={isOpen} />;
      }
      default: {
        return null;
      }
    }
  }, [layoutData.rightView, isOpen]);

  return (
    <RightBox paddingRight={3} paddingVertical={3} style={display}>
      <Section>
        <SectionHeader>
          <HeaderSelect
            options={SelectOptions.rightViews}
            value={layoutData.rightView}
            style={selectStyles}
            handleInputChange={onInputChange}
            name={"rightViewSelect"}
          />
        </SectionHeader>

        <SectionBody>{view()}</SectionBody>
      </Section>
    </RightBox>
  );
};

export default Right;
