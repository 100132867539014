import {
  Popover,
  PopoverBody,
  PopoverDivider,
  Box,
  CircleButton,
  Flex,
  FontAwesomeIcon,
  ButtonGroup,
  Button,
  FormSelect,
} from "@nef/core";
import { DynamicDropdownItem } from ".";
import React, { memo, useCallback, useEffect, useState, useMemo, useRef } from "react";
import { WorkXField } from "../fields";
import { useStandardTableDispatch } from "../standardTable";
import styled from "styled-components";
import { STANDARD_TABLE_DEFAULT_COLUMN_SET } from "wksConstants";

const width100 = { width: "100%" };
const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledScrollingBox = styled(Box)`
  overflow-y: overlay !important;
  overflow-x: hidden !important;
  min-height: 20px;
  max-height: 150px;
  justify-content: flex-start !important;
`;

const StyledNoScrollingBox = styled(Popover)`
  overflow: visible !important;
  max-height: 450px !important;
`;

const StyledPopoverBody = styled(PopoverBody)`
  overflow: visible;
`;

const StyledFirstFlex = styled(Flex)`
  margin-bottom: -1rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  padding-right: 10px;
  width: 100%;
  overflow: scroll;
`;

const SaveButton = styled(CircleButton)`
  margin-bottom: 0.8rem !important;
`;

export const PopoverDropdown2 = ({
  target,
  saveInputLabel,
  saveInputPlaceholder,
  onSave,
  onRemove,
  isOpen,
  togglePopover,
  options,
  activeOption,
  onClickOption,
  savingList,
  defaultLabel,
  defaultLayout,
  onChangeDefault,
  table,
  hiddenColumns,
  currentColumnSet,
  columnsOrder,
  allColumns,
}) => {
  const [leaveTimeout, setLeaveTimeout] = useState(null);
  const tableDispatch = useStandardTableDispatch();
  const toggleColumnSelector = useCallback(() => {
    tableDispatch({ type: "SET_COLUMN_SELECTOR_OPEN", payload: { table } });
    togglePopover(false);
  }, [table, tableDispatch, togglePopover]);

  // top
  const [saveAs, setSaveAs] = useState("");
  const ref = useRef();
  const modalRef = useRef();
  const optionKeys = useMemo(() => {
    return Object.keys(options);
  }, [options]);

  const handleChangeDefault = useCallback(
    option => {
      if (typeof onChangeDefault === "function") {
        onChangeDefault(option);
      }
    },
    [onChangeDefault]
  );

  const handleSaveAs = useCallback(() => {
    if (typeof onSave === "function") {
      onSave(saveAs);
    }
  }, [saveAs, onSave]);

  useEffect(() => {
    const setTo = activeOption === defaultLabel ? "" : activeOption;
    if (typeof activeOption === "string") {
      setSaveAs(setTo);
    } else {
      setSaveAs("");
    }
  }, [activeOption, defaultLabel]);

  // middle
  const handleClickOption = useCallback(
    option => {
      if (typeof onClickOption === "function") {
        onClickOption(option);
      }
    },
    [onClickOption]
  );

  // bottom
  const handleClickDefault = useCallback(() => {
    if (typeof onClickOption === "function") {
      onClickOption(null);
    }
  }, [onClickOption]);

  const handleRemoveOption = useCallback(
    option => {
      if (typeof onRemove === "function") {
        onRemove(option);
      }
    },
    [onRemove]
  );

  const handleMouseEnter = useCallback(() => {
    togglePopover(true);
    window.clearTimeout(leaveTimeout);
  }, [togglePopover, leaveTimeout]);

  const handleMouseLeave = useCallback(() => {
    window.clearTimeout(leaveTimeout);

    setLeaveTimeout(
      setTimeout(() => {
        togglePopover(false);
      }, 1000)
    );
  }, [leaveTimeout, togglePopover]);

  const updateSaveAs = useCallback(({ value }) => {
    setSaveAs(value);
  }, []);

  const handleStopPropogation = useCallback(() => e => e.stopPropagation(), []);

  useEffect(() => {
    const listener = event => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        modalRef.current?.contains(event.target)
      ) {
        return;
      }
      togglePopover(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [isOpen, ref, togglePopover]);

  const defaultLayouts = useMemo(() => {
    const defaultOptions = Object.keys(options).map(key => ({ label: key, value: key }));
    defaultOptions.push({
      label: STANDARD_TABLE_DEFAULT_COLUMN_SET,
      value: STANDARD_TABLE_DEFAULT_COLUMN_SET,
    });

    return defaultOptions;
  }, [options]);

  return (
    <>
      <StyledNoScrollingBox
        width={274}
        placement={"bottom"}
        isOpen={isOpen}
        target={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledPopoverBody padding={2} onClick={handleStopPropogation} ref={ref}>
          {/** Top */}

          <StyledFirstFlex alignItems="center" justifyContent="center">
            <Box paddingHorizontal={2} paddingVertical={1}>
              <WorkXField
                size="sm"
                label={saveInputLabel && `Save ${saveInputLabel} As`}
                value={saveAs}
                onClick={handleStopPropogation}
                handleInputChange={updateSaveAs}
                className="input"
                isRequired={true}
                optional={true}
                style={width100}
                maxLength={20}
                placeholder={saveInputPlaceholder || "new name"}
                invalid={saveAs.toUpperCase() === STANDARD_TABLE_DEFAULT_COLUMN_SET.toUpperCase()}
                feedback={`Cannot use the name '${STANDARD_TABLE_DEFAULT_COLUMN_SET}'`}
                helpText={
                  optionKeys.includes(saveAs)
                    ? `Save over '${saveAs}'`
                    : `Create new ${saveInputLabel || ""}`
                }
              />
            </Box>
            <Box marginLeft={2}>
              <SaveButton
                title="Save"
                onClick={handleSaveAs}
                size="sm"
                disabled={
                  saveAs.length === 0 ||
                  saveAs.toUpperCase() === STANDARD_TABLE_DEFAULT_COLUMN_SET.toUpperCase()
                }
                isLoading={savingList.length > 0}
              >
                <FontAwesomeIcon iconClassName={`fa-check`} />
              </SaveButton>
            </Box>
          </StyledFirstFlex>
          {/**  */}
          <PopoverDivider />

          {/** Middle */}
          <StyledScrollingBox>
            <StyledButtonGroup vertical size="sm">
              {Object.keys(options).map(key => (
                <DynamicDropdownItem
                  key={key}
                  text={key}
                  isActive={activeOption === key}
                  isSaving={savingList?.includes(key)}
                  isDisabled={savingList?.length > 0}
                  onClick={handleClickOption}
                  onRemove={handleRemoveOption}
                  modalOptions={{
                    header: "Delete Defaults",
                    body: "Please confirm the deletion of " + key,
                    ref: modalRef,
                  }}
                />
              ))}
            </StyledButtonGroup>
          </StyledScrollingBox>

          {/** */}
          <PopoverDivider />
          <DynamicDropdownItem
            key="defaultDynamicDropdownItem"
            text={defaultLabel}
            isActive={activeOption === STANDARD_TABLE_DEFAULT_COLUMN_SET}
            onClick={handleClickDefault}
          />
          <PopoverDivider />

          {/** Bottom */}
          {saveInputLabel === "Columns" && (
            <>
              <Box paddingHorizontal={4}>
                <FormSelect
                  isClearable={false}
                  options={defaultLayouts}
                  label={`Default Layout`}
                  value={defaultLayout}
                  onChange={handleChangeDefault}
                  size="sm"
                  optional={true}
                  helpText="This layout will be loaded as your default"
                />
              </Box>
              <PopoverDivider />
            </>
          )}

          {saveInputLabel === "Columns" && (
            <Box paddingHorizontal={2} paddingVertical={1}>
              <StyledButton size="xs" onClick={toggleColumnSelector}>
                Manage Columns
              </StyledButton>
            </Box>
          )}
        </StyledPopoverBody>
      </StyledNoScrollingBox>
    </>
  );
};

export default memo(PopoverDropdown2);
