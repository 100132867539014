import { NotificationHub } from "@nef/core";
import React, { memo, useCallback, useState, useMemo } from "react";
import { PopoverDropdown2 } from "components/popoverDropdown";
import { network as settingsNetwork } from "components/settings";
import { useUserContext } from "components/user";
import { useStandardTableContext, useStandardTableDispatch } from "./standardTableContext";
import { STANDARD_TABLE_DEFAULT_COLUMN_SET } from "wksConstants";

export const TableDropDown = ({ table, IconButton, id, selectedRows }) => {
  const [open, setOpen] = useState(false);
  const [user] = useUserContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [standardTableData] = useStandardTableContext();

  const buttonProps = useMemo(() => {
    const props = { ...IconButton };
    if (props.disabled) {
      props.disabled = {
        message: "Error retrieving user defaults. Please report the issue to WorkX Help Desk.",
      };
    }
    return props;
  }, [IconButton]);

  const togglePopover = useCallback(
    direction => {
      setOpen(direction === undefined ? !open : direction);
    },
    [setOpen, open]
  );

  const writeSuccessCallback = useCallback(
    key => {
      NotificationHub.send("success", `Saving Columns Sets to server was successful.`, {
        subtitle: `Column Set ${
          standardTableData[table].currentColumnSet || "no name"
        } was persisted`,
      });
      const actions = [
        {
          type: "SET_COLUMN_SAVING",
          payload: { table, column: key, isSaving: false },
        },
      ];
      standardTableDispatch(actions);
    },
    [standardTableDispatch, table, standardTableData]
  );

  const writeFailedCallback = useCallback(
    key => {
      NotificationHub.send("danger", `Saving Columns Sets to server did not succeede.`, {
        subtitle: `Column Set ${
          standardTableData[table].currentColumnSet || "no name"
        } was not persisted`,
      });

      standardTableDispatch({
        type: "SET_COLUMN_SAVING",
        payload: { table, column: key, isSaving: false },
      });
    },
    [standardTableDispatch, table, standardTableData]
  );

  const options = useMemo(() => {
    return standardTableData[table].columnSets;
  }, [standardTableData, table]);

  const write = useCallback(
    key => {
      const isNew = Object.keys(options).indexOf(key) === -1;
      if (
        isNew &&
        Object.keys(standardTableData[table].columnSets).length >=
          standardTableData[table].maxNumColumnSets
      ) {
        NotificationHub.send(
          "danger",
          `Unable to save more than ${standardTableData[table].maxNumColumnSets} column sets`
        );
      } else {
        const dispatches = [
          {
            type: "WRITE_COLUMN_SET",
            payload: { table, columnSetName: key },
          },
          {
            type: "SET_COLUMN_SAVING",
            payload: { table, column: key, isSaving: true },
          },
          {
            type: "LOAD_COLUMN_SET",
            payload: { table, columnSetName: key },
          },
        ];
        standardTableDispatch(dispatches);

        settingsNetwork().writeSettings(
          {
            data: JSON.stringify({
              ...standardTableData[table].columnSets,
              [key]: {
                hidden: standardTableData[table].hiddenColumns,
                visible: standardTableData[table].allColumns,
                order: standardTableData[table].columnsOrder,
              },
            }),
            name: table,
            type: "tableColumnSet",
          },

          writeSuccessCallback,
          writeFailedCallback,
          key
        );
      }
    },
    [
      writeFailedCallback,
      writeSuccessCallback,
      standardTableData,
      standardTableDispatch,
      table,
      options,
    ]
  );

  const setDefault = useCallback(() => {
    standardTableDispatch({
      type: "LOAD_DEFAULT_COLUMN_SET",
      payload: { table },
    });
  }, [standardTableDispatch, table]);

  const loadSet = useCallback(
    columnSetName => {
      if (typeof columnSetName === "string") {
        standardTableDispatch({
          type: "LOAD_COLUMN_SET",
          payload: {
            table,
            columnSetName,
          },
        });
      } else {
        setDefault();
      }
    },
    [standardTableDispatch, table, setDefault]
  );

  const removeSuccessCallback = useCallback(
    key => {
      NotificationHub.send("success", `Removing Columns Set from server was successful.`, {
        subtitle: `Column Set ${
          standardTableData[table].currentColumnSet || "no name"
        } was removed`,
      });

      standardTableDispatch({
        type: "SET_COLUMN_SAVING",
        payload: { table, column: key, isSaving: false },
      });
    },
    [standardTableDispatch, table, standardTableData]
  );

  const removeFailedCallback = useCallback(
    key => {
      NotificationHub.send("danger", `Removing Columns Set from server did not succeede.`, {
        subtitle: `Column Set ${
          standardTableData[table].currentColumnSet || "no name"
        } was not removed`,
      });

      standardTableDispatch({
        type: "SET_COLUMN_SAVING",
        payload: { table, column: key, isSaving: false },
      });
    },
    [standardTableDispatch, table, standardTableData]
  );

  const removeSet = useCallback(
    columnSetName => {
      const standardTableDispatches = [
        {
          type: "SET_COLUMN_SAVING",
          payload: { table, column: columnSetName, isSaving: true },
        },
        {
          type: "REMOVE_COLUMN_SET",
          payload: { table, columnSetName },
        },
      ];
      standardTableDispatch(standardTableDispatches);
      const data = { ...standardTableData[table].columnSets };
      delete data[columnSetName];
      settingsNetwork().writeSettings(
        {
          data: JSON.stringify(data),
          name: table,
          type: "tableColumnSet",
        },
        removeSuccessCallback,
        removeFailedCallback,
        columnSetName
      );
    },
    [standardTableDispatch, table, standardTableData, removeFailedCallback, removeSuccessCallback]
  );

  const changeDefaultSuccessCallback = useCallback(
    columnSetName => {
      NotificationHub.send("success", `'${columnSetName}' was saved as the default layout.`);
    },
    [standardTableDispatch, table, standardTableData]
  );

  const changeDefaultFailedCallback = useCallback(
    columnSetName => {
      NotificationHub.send("danger", `'${columnSetName}' failed to save as the default layout.`);
    },
    [standardTableDispatch, table, standardTableData]
  );

  const onChangeDefault = useCallback(
    e => {
      const optionValue = e.value.value;
      standardTableDispatch({
        type: "WRITE_COLUMN_SET_DEFAULT",
        payload: { table, columnSetName: optionValue },
      });
      settingsNetwork().writeSettings(
        {
          data: JSON.stringify({ default: optionValue }),
          name: table,
          type: "tableColumnSetDefault",
        },

        changeDefaultSuccessCallback,
        changeDefaultFailedCallback,
        optionValue
      );
    },
    [changeDefaultFailedCallback, changeDefaultSuccessCallback, standardTableDispatch, table]
  );

  const defaultColumnSet = useMemo(
    () => ({
      value: standardTableData[table].columnSetDefault,
      label: standardTableData[table].columnSetDefault,
    }),
    [standardTableData, table]
  );

  return (
    <>
      {<IconButton.thing key="iconbuttonthing" {...buttonProps} onClick={togglePopover} id={id} />}
      <PopoverDropdown2
        key="dropdown"
        target={id}
        isOpen={open}
        togglePopover={togglePopover}
        saveInputLabel="Columns"
        options={options}
        activeOption={standardTableData[table].currentColumnSet}
        savingList={standardTableData[table].columnSavingList}
        onClickOption={loadSet}
        onChangeDefault={onChangeDefault}
        onRemove={removeSet}
        onSave={write}
        defaultLabel={STANDARD_TABLE_DEFAULT_COLUMN_SET}
        defaultLayout={defaultColumnSet}
        table={table}
        selectedRows={selectedRows}
        hiddenColumns={standardTableData[table].hiddenColumns}
        currentColumnSet={standardTableData[table].currentColumnSet}
        columnsOrder={standardTableData[table].columnsOrder}
        allColumns={standardTableData[table].allColumns || []}
      />
    </>
  );
};

export default memo(TableDropDown);
