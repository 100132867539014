import { NotificationHub } from "@nef/core";
import { network } from "components/settings";
import { getPersistedCreatableName } from "./constants";

export const handlePersistCreatableOptions = ({
  persistKey,
  user,
  userDispatch,
  newOptionsString,
}) => {
  const onPersistSuccess = persistedItem => {
    const newUserData = Array.from(user.userData);
    let itemExists = false;
    for (let idx in newUserData) {
      const item = newUserData[idx];
      if (item.name === getPersistedCreatableName(persistKey)) {
        item.data = persistedItem.data;
        item.type = persistedItem.type;
        itemExists = true;
        break;
      }
    }
    if (!itemExists) {
      newUserData.push(persistedItem);
    }
    userDispatch({
      type: "SET_USER_DATA",
      payload: {
        userData: newUserData,
      },
    });
  };

  const onPersistError = () => {
    NotificationHub.send(
      "danger",
      "An error occurred while persisting the option that was created."
    );
  };

  const item = {
    data: newOptionsString,
    name: getPersistedCreatableName(persistKey),
    type: "SELECT_PERSIST_CREATABLE",
  };
  network().writeSettings(item, onPersistSuccess, onPersistError, item);
};
