import { NotificationHub } from "@nef/core";
import { convertSampleAPIData } from ".";
import { AckTypes, StandardTables, TableButtonAction, TradeStatusVals } from "../../wksConstants";

export const errorCallbackInner = ({ r, dispatch, error, id, controlNum, table, text }) => {
  let dispatches = [];
  if (r.json) {
    const key = r.json.controlNum
      ? { value: controlNum || r.json.controlNum, key: "controlNum" }
      : { value: id, key: "id" };
    dispatches.push({
      type: "UPDATE_CELLS",
      payload: {
        table,
        key,
        values: [
          { key: "status", value: "danger" },
          {
            key: "statusText",
            value: {
              statusText: error.message,
              tradeActionType: text,
              ackType: "error",
            },
          },
        ],
      },
    });
  } else {
    const key = r.controlNum
      ? { value: controlNum || r.controlNum, key: "controlNum" }
      : { value: id || r.id, key: "id" };

    dispatches.push({
      type: "UPDATE_CELLS",
      payload: {
        table,
        key,
        values: [
          { key: "status", value: "danger" },
          {
            key: "statusText",
            value: {
              statusText: error?.message,
              tradeActionType: text,
              ackType: "error",
            },
          },
        ],
      },
    });

    NotificationHub.send("danger", error.message);
  }

  dispatch(dispatches);
};

export const successCallbackInner = ({ responses, id, controlNum, dispatch, actionId, table }) => {
  const values = [
    { key: "status", value: "" },
    { key: "statusText", value: [] },
    { key: "tradeStatus", value: "" },
  ];
  let dispatches = [];

  if (![TableButtonAction.REVERSE].includes(actionId)) {
    // build an ungly and shameful object that the orb table cell can parse into a tooltip
    // TODO: wow, changing this won't cause any bugs
    // why not a pojo jeff?
    let globalReject; // red or green orb
    let isBroken = false;

    responses.forEach((r, i) => {
      const statusText =
        r.json.message || r.json.rejectText ? r.json.message || r.json.rejectText : undefined;
      values[0].value =
        (r.json.errorType || r.json.ackType === AckTypes.REJECT) && !isBroken
          ? "danger"
          : "success";
      values[1].value.push({
        statusText,
        ackType: r.json.ackType,
        tradeActionType: r.json.tradeActionType || actionId,
      });

      if (
        ((r.json.tradeStatus && r.json.ackType !== AckTypes.REJECT) ||
          (r.json.ackType !== AckTypes.REJECT && r.json.rejectText === null)) &&
        !r.json.errorType
      ) {
        const tradeStatus = TradeStatusVals[r.json.tradeStatus];
        if (
          (r.json.tradeActionType === TableButtonAction.BREAK && r.json.tradeStatus === "B") ||
          r.json.tradeActionType !== TableButtonAction.BREAK ||
          (r.json.tradeActionType === TableButtonAction.BREAK && values[2] === "")
        ) {
          values[2].value = tradeStatus;
          if (r.json.tradeStatus === "B") {
            globalReject = false;
            isBroken = true;
          }
        }
        if (actionId === TableButtonAction.CLOSE) {
          const key = responses[0].json.controlNum ? "controlNum" : "id";
          const data = responses[0].json.controlNum
            ? responses[0].json.controlNum
            : r.json.id || id;

          dispatches.push({
            type: "DELETE_ROW_BY_VALUE",
            payload: {
              table: table,
              key,
              value: data,
            },
          });
        }
      } else {
        if (actionId === TableButtonAction.CLOSE) {
          if (table === StandardTables.TR_REPORT) {
            dispatches.push({
              type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
              payload: { ask: true, table: table },
            });
          } else {
            values[0].value = "danger";
            values[1].value = [
              {
                statusText: r.json.debugMessage,
                ackType: AckTypes.REJECT,
                tradeActionType: TableButtonAction.CLOSE,
              },
            ];
          }
        } else if (!isBroken) {
          globalReject = true;
        }
      }
    });
    const compareLength = values[2]?.value?.length
      ? values[2].value.length
      : Object.keys(values[2]?.value || {})?.length;

    // if we have no value, pop - don't change status
    if (compareLength === 0 || globalReject === true) {
      values.pop();
    }

    if (actionId === TableButtonAction.BREAK) {
      const partMM = "1";
      const partOE = "2";
      const doneMM = "3";
      const doneOE = "4";

      const getBreakStateFromJson = json => {
        switch (json.breakState) {
          case "S":
          case "B":
            if (json.brokenFrom === "exec") {
              return partMM;
            } else {
              return partOE;
            }
          case "X":
          case "x":
            if (json.brokenFrom === "exec") {
              return doneMM;
            } else {
              return doneOE;
            }
          default:
            return "";
        }
      };

      let breakState1;
      let breakState2;
      if (responses[0]?.json) {
        breakState1 = getBreakStateFromJson(responses[0].json);
      }
      if (responses[1]?.json) {
        breakState2 = getBreakStateFromJson(responses[1].json);
      }

      let breakState = breakState1;
      if (breakState2 !== "") {
        if ([doneOE, doneMM].includes(breakState2) || breakState === "") {
          breakState = breakState2;
        }
      }

      values.push({
        key: "breakState",
        value: breakState,
      });
    }
    const key =
      responses[0].json.controlNum || controlNum
        ? { value: responses[0].json.controlNum || controlNum, key: "controlNum" }
        : {
            value: responses[0].json.id || id,
            key: "id",
          };
    dispatches.push({
      type: "UPDATE_CELLS",
      payload: {
        table: table,
        key,
        values: values,
      },
    });

    // dispatch(dispatches);
  } else if (actionId === TableButtonAction.REVERSE) {
    // reverse gets act response, so we have to parse shape differently
    const r = convertSampleAPIData(responses)[0].json;
    if (r.rejectText?.length || r.errorType) {
      if (table === StandardTables.TR_REPORT) {
        dispatches.push({
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { ask: true, table: table },
        });
      }

      values[0].value = "danger";
      values[1].value = [
        {
          statusText: r.rejectText ? r.rejectText : r.message,
          ackType: AckTypes.REJECT,
          tradeActionType: TableButtonAction.REVERSE,
        },
      ];
      values.pop();
      const key = controlNum ? { value: controlNum, key: "controlNum" } : { value: id, key: "id" };

      dispatches.push({
        type: "UPDATE_CELLS",
        payload: {
          table: table,
          key,
          values: values,
        },
      });
    } else {
      values[0].value = "success";
      values[1].value = [
        {
          ackType: AckTypes.SUCCESS,
          tradeActionType: TableButtonAction.REVERSE,
        },
      ];
      values.pop();

      const key = controlNum ? { value: controlNum, key: "controlNum" } : { value: id, key: "id" };

      dispatches.push(
        {
          type: "UPDATE_CELLS",
          payload: {
            table: table,
            key,
            values: values,
          },
        },
        {
          type: "ADD_ROW_BEFORE",
          payload: {
            table: table,
            row: convertSampleAPIData([
              Object.assign({}, r, {
                key,
                status: "success",
                statusText: {
                  ackType: AckTypes.SUCCESS,
                  tradeActionType: TableButtonAction.REVERSE,
                },
              }),
            ])[0],
            key,
          },
        }
      );
    }
  }

  dispatch(dispatches);
};
