import _ from "lodash";
import { doFetch } from "./network.doFetch";
import { ApiResponseNames } from "components/fields";
import { AckTypes, TableButtonAction } from "wksConstants";

// handle batching of action requests
export const doAction = async (actionId, fieldData, callback, errorCallback, text) => {
  switch (actionId) {
    default:
      let slice = 0;
      let done = false;
      const sliceSize = 10;
      let currentData;
      let allPromises = [];
      let currentPromises;

      while (!done) {
        currentData = fieldData.slice(slice, sliceSize + slice);
        currentPromises = currentData.map(d => {
          if (d.then) {
            return d;
          }

          if (Array.isArray(d)) {
            return Promise.all(d);
          }

          // this is the orignal table do fetch
          return doFetch(d, actionId, callback, args => {
            errorCallback(args, text);
          });
        });

        // wait on this slice of requests to finish
        allPromises = allPromises.concat(await Promise.all(currentPromises));

        if (slice >= fieldData.length) {
          done = true;
        } else {
          slice += sliceSize;
        }
      }

      // and then process all of the response slices as a group
      let success = {};
      let fail = {};
      const badRequestCount = {};
      const successContNums = [];
      const successOriginalContNums = {};
      const failContNums = [];

      const rows = allPromises;
      if (rows[0] === undefined) {
        // server errors, throw and halt
        return { success: [], fail: [] };
      }

      const flat = _.flatten(allPromises);

      for (let row in flat) {
        const { json, response } = flat[row];
        let isControlNum = false;
        let rowId = json.controlNum;

        if (rowId) {
          isControlNum = true;
        } else {
          rowId = json.id;
        }

        if (
          json.ackType === AckTypes.REJECT ||
          json.tradeStatus === "-" ||
          json.rejectText?.length ||
          json.errorType ||
          json.error ||
          json?.errorCode
        ) {
          if (isControlNum) {
            failContNums.push(json.controlNum);
          }
          if (json.errorType && response?.status === 400) {
            if (typeof badRequestCount[json.message] === "number") {
              badRequestCount[json.message]++;
            } else {
              badRequestCount[json.message] = 1;
            }
          }
          if (rowId !== undefined) {
            fail[rowId] = true;
          }
        } else {
          if (isControlNum) {
            if (
              actionId === TableButtonAction.REVERSE &&
              json[ApiResponseNames.originalControlNum]
            ) {
              successOriginalContNums[json[ApiResponseNames.originalControlNum]] =
                json[ApiResponseNames.controlNum];
            }
            successContNums.push(json[ApiResponseNames.controlNum]);
          }
          success[rowId] = true;
        }
      }

      return {
        success: {
          length: Object.keys(success).length,
          controlNums: _.uniq(successContNums),
          originalControlNums: successOriginalContNums,
        },
        fail: {
          length: Object.keys(fail).length,
          controlNums: _.uniq(failContNums),
          badRequestCount,
        },
      };
  }
};

// When action is break, we need two requests. except sometimes
const doMultiBreak = async (r, action, callback, errorCallback) => {
  let breakResults = [];
  const result1 = await doFetch(r[0], action, () => {}, errorCallback);

  if (!result1.json.rejectText) {
    if (["x", "X"].includes(result1?.json[ApiResponseNames.breakState])) {
      breakResults = [Promise.resolve(result1)];
    } else {
      const result2 = await doFetch(r[1], action, () => {}, errorCallback);

      if (["x", "X"].includes(result2?.json[ApiResponseNames.breakState])) {
        breakResults = [Promise.resolve(result2)];
      }
      breakResults = [Promise.resolve(result1), Promise.resolve(result2)];
    }
  } else {
    const result2 = await doFetch(r[1], action, () => {}, errorCallback);

    if (!result2.json.rejectText) {
      if (["x", "X"].includes(result2?.json[ApiResponseNames.breakState])) {
        breakResults = [Promise.resolve(result2)];
      }
    } else {
      breakResults = [Promise.resolve(result1), Promise.resolve(result2)];
    }
  }

  callback(await Promise.all(breakResults));
  return await Promise.all(breakResults);
};

// figure out which breaks need two requests
// proxy those through a function that makes both calls,
// and returns a promise,
// then pass all those promises (multi and single) along
export const breakProxy = async (fieldData, callback, errorCallback, text) => {
  const { double, single } = fieldData.reduce(
    (acc, curr) => {
      if (
        curr.contra &&
        curr.exec &&
        curr.contra !== curr.exec &&
        !["X", "C", "K"].includes(curr.side)
      ) {
        acc.double.push(curr);
      } else {
        acc.single.push(curr);
      }
      return acc;
    },
    { double: [], single: [] }
  );

  single.forEach(s => {
    if (s.contra) {
      s.mpid = s.contra;
      s.willBreakFrom = "contra";
    }

    if (s.exec) {
      s.mpid = s.exec;
      s.willBreakFrom = "exec";
    }
  });

  const allDoubles = [];

  for (let d in double) {
    const multis = ["contra", "exec"].map(r => {
      const newD = { ...double[d] };
      if (r === "contra") {
        newD.mpid = newD.contra;
        newD.willBreakFrom = "contra";
      } else {
        newD.mpid = newD.exec;
        newD.willBreakFrom = "exec";
      }
      return newD;
    });

    allDoubles.push(multis);
  }

  let done = false;
  let slice = 0;
  const sliceSize = 10;
  let currentDoubles;
  let allBreaks = single;

  while (!done) {
    currentDoubles = allDoubles.slice(slice, sliceSize + slice);
    const currentPromises = currentDoubles.map(d =>
      doMultiBreak(d, TableButtonAction.BREAK, callback, errorCallback)
    );

    allBreaks = allBreaks.concat(await Promise.all(currentPromises));

    if (slice >= allDoubles.length - 1) {
      done = true;
    } else {
      slice += sliceSize;
    }
  }

  return doAction(TableButtonAction.BREAK, allBreaks, callback, errorCallback, text);
};
