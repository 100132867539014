import { Button, CSSGrid, Header, NotificationHub } from "@nef/core";
import {
  FieldLoop,
  tradeReportingFields1,
  tradeReportingFields2,
  tradeReportingFields3,
} from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { Divider } from "components/global";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { useCallback, useState } from "react";
import { useSettingsContext } from ".";
import { SettingsWrapper } from "./settingsWrapper";
import { FooterFlex } from "./styled";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.25rem;
`;

const dividerMargin = "1rem 0";
const tradeReportingKey = "SettingsTradeReporting";
export const TradeReportingSettings = ({ augmentOnChange }) => {
  const [formData] = useFormContext();
  const [user] = useUserContext();
  const [settings, settingsDispatch] = useSettingsContext();
  const [isLoading, setLoading] = useState(false);

  const onSaveSuccess = useCallback(() => {
    setLoading(false);
    NotificationHub.send("success", "User preference update persisted");
  }, []);

  const onSaveError = useCallback(() => {
    setLoading(false);
    NotificationHub.send("danger", "Failed to persist user preference", {
      subtitle: "Please try again",
    });
  }, []);

  const handleSave = useCallback(() => {
    setLoading(true);
    const settingsReference = settings[`${tradeReportingKey}Keys`];
    const settingsData = {};

    settingsReference.forEach(key => {
      settingsData[key] = formData[tradeReportingKey].fields[key];
    });

    settingsDispatch({
      type: "SET_SETTINGS",
      payload: { fields: formData[tradeReportingKey].fields },
    });

    network().writeSettings(
      {
        data: JSON.stringify(settingsData),
        name: tradeReportingKey,
        type: tradeReportingKey,
      },
      onSaveSuccess,
      onSaveError
    );
  }, [onSaveError, onSaveSuccess, formData, settings, settingsDispatch]);

  return (
    <>
      <SettingsWrapper
        header="Trade Reporting"
        subheader="Control UI options for Trade Reporting functionality"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
      >
        <Grid>
          <Header size={3}>Sticky Fields</Header>
          <FieldLoop
            form={Forms.SETTINGS_TR}
            fields={tradeReportingFields1}
            augmentOnChange={augmentOnChange}
            isReactFragment={true}
          />
        </Grid>
        <Divider margin={dividerMargin} />
        <Grid>
          <Header size={3}>Are You Sure Pop-Ups</Header>
          <FieldLoop
            form={Forms.SETTINGS_TR}
            fields={tradeReportingFields2}
            augmentOnChange={augmentOnChange}
          />
          <FieldLoop
            form={Forms.SETTINGS_TR}
            fields={tradeReportingFields3}
            augmentOnChange={augmentOnChange}
          />
        </Grid>
      </SettingsWrapper>
      <FooterFlex justifyContent="flex-end" flexDirection="rows">
        <Button disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
      </FooterFlex>
    </>
  );
};
