import { FieldNames } from "./fieldConstants";
import { final } from "./fields";
import * as fields from "./fieldSubsetsConstants.js";
import pick from "lodash/pick";
import { RASH_FIELD_PREFIX, RASH_ORDER } from "components/orderEntry/constants";
import { addPrefixToField } from "utils/js.utils";

export const RDAGU = {
  MyMPID: final[FieldNames.mpid],
  QueryType: final[FieldNames.agreementQueryType],
};

export const RDCUSIP = {
  SymbolOrCUSIP: final[FieldNames.symbolOrCUSIP],
  Symbol: final[FieldNames.symbol],
  CUSIP: final[FieldNames.CUSIP],
};

export const RDCLEARING = {
  MyMPID: final[FieldNames.clearingScanMPID],
  mpidType: final[FieldNames.MPIDType],
};

export const commonFormLeft = [
  { key: "fields", fields: fields.fields },
  { key: "fields2", fields: fields.fields2 },
  { key: "commonPriceFields", fields: fields.commonPriceFields },
  { key: "fields3", fields: fields.fields3 },
];

export const commonFormRight = [
  { key: "fields3b", fields: fields.fields3b },
  { key: "hiddenFields", fields: fields.hiddenFields },
  { key: "hiddenFields2", fields: fields.hiddenFields2 },
];

export const entryFormLeft = [
  { key: "fields", fields: fields.fields },
  { key: "fields2", fields: fields.fields2 },
  { key: "entryFormPriceFields", fields: fields.entryFormPriceFields },
  { key: "fields3", fields: fields.fields3 },
];

export const entryFormRight = commonFormRight.concat([
  { key: "reversalFields", fields: fields.reversalFields },
]);

export const scanFieldsA = pick(final, [
  FieldNames.mpid,
  FieldNames.scanGiveupWithMyMpid,
  FieldNames.scanReportedByMyMpid,
]);

export const scanFieldsB = pick(final, [
  FieldNames.trf,
  FieldNames.clearReportRiskVals,
  FieldNames.reportingParty,
  FieldNames.agreement,
  FieldNames.counterMPID,
  FieldNames.correspondentMPID,
]);

export const scanFormLeft = [
  { key: "scanOnly", fields: fields.scanOnly },
  { key: "scanFieldsA", fields: scanFieldsA },
  { key: "scanFieldsB", fields: scanFieldsB },
  { key: "fields2", fields: fields.fields2ForScan },
  { key: "commonPriceFields", fields: fields.commonPriceFields },
  { key: "fields3", fields: fields.fields3 },
];

export const scanFormRight = commonFormRight
  .slice(0, commonFormRight.length - 1)
  .concat([
    { key: "scanOnly2", fields: fields.scanOnly2 },
    { key: "scanOnly3", fields: fields.scanOnly3 },
  ])
  .concat(commonFormRight.slice(commonFormRight.length - 1, commonFormRight.length));

export const rejectsFieldsMain = pick(final, [
  FieldNames.mpid,
  FieldNames.scanAllPartiesWithMyMPID,
]);

export const rejectsFields = pick(final, [FieldNames.trf, FieldNames.counterMPID]);

export const rejectsFormLeft = [
  { key: "rejectsFieldsMain", fields: rejectsFieldsMain },
  { key: "rejectsFields", fields: rejectsFields },
];

export const rejectsFields3 = pick(final, [
  FieldNames.dateRange,
  FieldNames.tradeTimeRange,
  FieldNames.side,
  FieldNames.symbol,
]);

export const rejectsFields2 = pick(final, [FieldNames.submissionDate, FieldNames.rejectTexts]);
export const rejectsFormRight = [
  { key: "rejectsFields2", fields: rejectsFields2 },
  { key: "rejectsFields3", fields: rejectsFields3 },
];

export const qtFormLeft = [
  { key: "qtOne", fields: fields.queryToolOne },
  { key: "qtTwo", fields: fields.queryToolTwo },
  { key: "qtThree", fields: fields.queryToolThree },
];
export const qtFormRight = [
  { key: "qtFour", fields: fields.queryToolFour },
  { key: "qtFive", fields: fields.queryToolFive },
  { key: "qtSix", fields: fields.queryToolSix },
];

export const settingsLimoAll = [
  { key: "isPostTradeRiskServiceActive", fields: fields.isPostTradeRiskServiceActive },
  { key: "riskConfig1a", fields: fields.riskConfig1a },
  { key: "riskConfig1b", fields: fields.riskConfig1b },
  { key: "riskConfig2", fields: fields.riskConfig2 },
  { key: "riskConfig2BuySell", fields: fields.riskConfig2BuySell },
  { key: "riskConfig2Net", fields: fields.riskConfig2Net },
  { key: "riskConfig3", fields: fields.riskConfig3 },
  { key: "riskConfig4", fields: fields.riskConfig4 },
  { key: "riskConfig5", fields: fields.riskConfig5 },
  { key: "riskConfig6", fields: fields.riskConfig6 },
];

export const ptrAuditAll = [
  { key: "ptrAudit1", fields: fields.ptrAuditFields1 },
  { key: "ptrAudit2", fields: fields.ptrAuditFields2 },
];

export const riskConfigMPIDsForm = [{ key: "riskConfigMPIDs", fields: fields.riskConfigMPIDs }];
export const intradayBuySellLimitsForm = [
  { key: "intradayBuySellLimits", fields: fields.intradayBuySellLimits },
];

export const settingsRiskAlertNew = [
  { key: "riskAlertFields1", fields: fields.riskAlertFields1 },
  { key: "riskAlertFields3a", fields: fields.riskAlertFields3a },
  { key: "riskAlertFields3b", fields: fields.riskAlertFields3b },
  { key: "riskAlertFields4", fields: fields.riskAlertFields4 },
  { key: "riskAlertFields5a", fields: fields.riskAlertFields5a },
  { key: "riskAlertFields5b", fields: fields.riskAlertFields5b },
];

export const settingsAguRiskAlertNew = [
  { key: "riskAlertFieldsAguMPID", fields: fields.riskAlertFieldsAguMPID },
  { key: "riskAlertFields3b", fields: fields.riskAlertFields3b },
  { key: "riskAlertFields5a", fields: fields.riskAlertFields5a },
  { key: "riskAlertFields5b", fields: fields.riskAlertFields5b },
];

export const ptraEmailManager = [
  { key: "ptraEmailManagerFields", fields: fields.ptraEmailManagerFields },
];

export const eqrcAuditMenu = [
  { key: "eqrcMpidPortExch", fields: fields.eqrcMpidPortExchangeFields },
  { key: "eqrcGroupId", fields: fields.eqrcGroupId },
  { key: "eqrcDateRange", fields: fields.eqrcDateRange },
];

export const eqrcHistoryMenu = [
  { key: "eqrcMpidPortExch", fields: fields.eqrcMpidPortExchangeFields },
  { key: "eqrcGroupId", fields: fields.eqrcGroupId },
  { key: "eqrcRuleTypeAlertLevel", fields: fields.eqrcRuleTypeAlertLevel },
  { key: "eqrcDateRange", fields: fields.eqrcDateRange },
];

export const eqrcActionsForm = [
  { key: "eqrcActions", fields: fields.eqrcActionsFields },
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
];

export const eqrcFatFingerForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "fatFingerFields", fields: fields.eqrcFatFingerFields },
];

export const eqrcRestrictedStockForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcRestrictedStockFields },
];

export const eqrcGrossExposureForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "groupId", fields: fields.eqrcGroupId },
  { key: "restrictedStockFields", fields: fields.eqrcGrossExposureFields },
];

export const eqrcMarketImpactForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcMarketImpactFields },
];

export const eqrcOrderTypeForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcOrderTypeFields },
];

export const eqrcAvgDailyVolForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcAverageDailyVolumeFields },
];

export const eqrcOrderRateForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcOrderRateThresholdsFields },
];

export const eqrcShortSaleForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcShortSaleFields },
];

export const eqrcAlertConfigForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcAlertConfigurationFields },
];

export const eqrcNewAlertForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcAlertConfigurationFromRuleFields },
];

export const eqrcMaxNotionalOrderForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcMaxNotionalOrder },
];

export const eqrcMaxSharesPerOrderForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcMaxSharesPerOrder },
];

export const eqrcSharesLocatedCheckForm = [
  { key: "mpidPortExchange", fields: fields.eqrcMpidPortExchangeFields },
  { key: "restrictedStockFields", fields: fields.eqrcSharesLocatedCheck },
];

export const orderEntryLeft = [
  {
    key: "oeLeft_1",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.symbol),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.display),
    ]),
  },
  {
    key: "oeLeft_2",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.side),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.price),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.orderQuantity),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.maxFloor),
    ]),
  },
  {
    key: "oeLeft_3",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.route),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.exchange),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.timeInForce),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.capacity),
    ]),
  },
  {
    key: "oeLeft_4",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.intermarketSweep),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.crossType),
    ]),
  },
];

export const orderEntryRight = [
  {
    key: "oeRight_1",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegDifference),
    ]),
  },
  {
    key: "oeRight_2",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegType),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPrice),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegDifference),
    ]),
  },
  {
    key: "oeRight_3",
    fields: pick(final, [
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.minQuantity),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.reactiveTradeNow),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.subId),
      addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.customerType),
    ]),
  },
];
