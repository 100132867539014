import { Button, Flex, NotificationHub } from "@nef/core";
import { FieldLoop, statsFields } from "components/fields";
import { useFormContext, useFormDispatch } from "components/form";
import React, { useCallback, useState } from "react";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { Forms } from "components/fields/fieldConstants";
import { SettingsWrapper, useSettingsContext } from ".";
import { FooterFlex } from "./styled";

const statsKey = "SettingsStats";
export const StatsSettings = ({ augmentOnChange }) => {
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [user] = useUserContext();
  const [settings, settingsDispatch] = useSettingsContext();
  const [isLoading, setLoading] = useState(false);

  const onSaveSuccess = useCallback(() => {
    setLoading(false);
    NotificationHub.send("success", "User preference update persisted");
  }, []);

  const onSaveError = useCallback(() => {
    setLoading(false);
    NotificationHub.send("danger", "Failed to persist user preference", {
      subtitle: "Please try again",
    });
  }, []);

  const handleSave = useCallback(() => {
    if (
      formData[statsKey].fields.incContraMPID === false &&
      formData[statsKey].fields.incExecMPID === false
    ) {
      formDispatch({
        type: "SET_GLOBAL_ERROR",
        payload: {
          message: "Either Executing MPID or Contra MPID must be checked",
          form: Forms.SETTINGS_STATS,
        },
      });

      return false;
    } else {
      formDispatch({
        type: "SET_GLOBAL_ERROR",
        payload: {
          message: "",
          form: Forms.SETTINGS_STATS,
        },
      });
    }
    setLoading(true);
    const settingsReference = settings[`${statsKey}Keys`];
    const settingsData = {};

    settingsReference.forEach(key => {
      settingsData[key] = formData[statsKey].fields[key];
    });

    settingsDispatch({
      type: "SET_SETTINGS",
      payload: { fields: formData[statsKey].fields },
    });

    network().writeSettings(
      {
        data: JSON.stringify(settingsData),
        name: statsKey,
        type: statsKey,
      },
      onSaveSuccess,
      onSaveError
    );
  }, [onSaveError, onSaveSuccess, formData, formDispatch, settings, settingsDispatch]);

  return (
    <>
      <SettingsWrapper
        header="Real Time Stats / Historical Trends"
        subheader="Control which data are included in stats displays"
        errorMessage={formData[Forms.SETTINGS_STATS.key].globalErrorMessage}
      >
        <Flex flexDirection="column">
          <FieldLoop
            form={Forms.SETTINGS_STATS}
            fields={statsFields}
            augmentOnChange={augmentOnChange}
          />
        </Flex>
      </SettingsWrapper>
      <FooterFlex justifyContent="flex-end" flexDirection="rows">
        <Button disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
      </FooterFlex>
    </>
  );
};
