import { FieldLoop, FieldNames, LimitMonitorSelect } from "components/fields";
import { Forms, SelectOptions } from "components/fields/fieldConstants";
import React, { useCallback, useEffect } from "react";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { getRelationships } from "./constants";
import { SELECT_ALL, SettingNames, StandardTables } from "../../wksConstants";
import { useLayoutContext } from "../context";
import { useRefDataContext } from "../refData/context";
import { useStandardTableDispatch } from "../standardTable";
import { useTabTableDispatch } from "../tabTable";
import { useAggregateLimitDispatch } from "./aggregates";
import { useLimitMonitorContext, useLimitMonitorDispatch } from "./context";
import { useHKCacheContext, useHKCacheDispatch } from "./heldKilledCache";

export const LimitMonitorSelects = ({ height }) => {
  const [, layoutDispatch] = useLayoutContext();
  const [limoData] = useLimitMonitorContext();
  const limoDispatch = useLimitMonitorDispatch();
  const aggregatesDispatch = useAggregateLimitDispatch();
  const standardTableDispatch = useStandardTableDispatch();
  const tabTableDispatch = useTabTableDispatch();
  const [hkCacheData] = useHKCacheContext();
  const hkCacheDispatch = useHKCacheDispatch();
  const [user] = useUserContext();
  const [refData] = useRefDataContext();

  useEffect(() => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () =>
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
  }, [layoutDispatch]);

  useEffect(() => {
    return () => {
      aggregatesDispatch({ type: "SET_SUMMARY_POLLING", payload: false });
      hkCacheDispatch([
        { type: "SET_SUMMARY_POLLING", payload: false },
        { type: "SET_SHOULD_ABORT", payload: true },
      ]);
    };
  }, [aggregatesDispatch, hkCacheDispatch]);

  useEffect(() => {
    if (Object.keys(limoData.clearingRelationships).length > 0) {
      aggregatesDispatch({ type: "SET_SUMMARY_POLLING", payload: true });
    } else {
      aggregatesDispatch({ type: "SET_SUMMARY_POLLING", payload: false });
    }
  }, [limoData.clearingRelationships, aggregatesDispatch, hkCacheDispatch]);

  useEffect(() => {
    standardTableDispatch([
      {
        type: "SET_DATA_EMPTY",
        payload: {
          table: StandardTables.CV_HELD,
        },
      },
      {
        type: "SET_DATA_EMPTY",
        payload: {
          table: StandardTables.CV_KILL_REJECT,
        },
      },
    ]);
    if (Object.keys(hkCacheData.clearingRelationshipIds).length > 0) {
      hkCacheDispatch([
        { type: "SET_LOADING_AND_ABORT", payload: true },
        { type: "SET_SUMMARY_POLLING", payload: true },
      ]);
    } else {
      hkCacheDispatch([
        { type: "SET_SHOULD_ABORT", payload: true },
        { type: "SET_SUMMARY_POLLING", payload: false },
      ]);
    }
  }, [
    hkCacheData.clearingRelationshipIds,
    hkCacheDispatch,
    tabTableDispatch,
    standardTableDispatch,
  ]);

  const noop = useCallback(() => {}, []);

  const handleSaveSelection = useCallback(
    (value, selectAll) => {
      let dataStr = JSON.stringify({ [FieldNames.mpid]: value });
      if (selectAll) {
        dataStr = JSON.stringify({ [FieldNames.mpid]: SELECT_ALL });
      }
      network().writeSettings(
        {
          data: dataStr,
          name: SettingNames.LIMO_MPID,
          type: SettingNames.LIMO_MPID,
        },
        noop, // onSaveSuccess,
        noop // onSaveError
      );
    },
    [noop]
  );

  const handleSubmit = useCallback(
    ({ value }) => {
      const options = SelectOptions[FieldNames.mpid](Forms.CV_TOP);
      const numOptions = options.reduce((acc, curr) => {
        acc++;
        if (Array.isArray(curr.children)) {
          acc += curr.children.length;
        }
        return acc;
      }, 0);
      let selectAll = false;
      if (Array.isArray(value)) {
        if (numOptions === value.length) {
          selectAll = true;
        }
        const { clearingRelationships, clearingRelationshipIds } = getRelationships(
          refData.correspondentMpidRelationships,
          value
        );

        limoDispatch({ type: "SET_CLEARING_RELATIONSHIPS", payload: clearingRelationships });
        hkCacheDispatch({
          type: "SET_CLEARING_RELATIONSHIP_IDS",
          payload: clearingRelationshipIds,
        });
        standardTableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.CV_SUMMARY },
        });
        handleSaveSelection(value, selectAll);
      }
    },
    [
      refData.correspondentMpidRelationships,
      limoDispatch,
      hkCacheDispatch,
      standardTableDispatch,
      handleSaveSelection,
    ]
  );

  return (
    <FieldLoop
      form={Forms.CV_TOP}
      fields={LimitMonitorSelect}
      showLabel={false}
      augmentOnChange={handleSubmit}
    />
  );
};
